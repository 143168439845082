import Image from "next/image";
import { useMemo, useRef } from "react";

import { LinkPositions, NavigationLink } from "./types";

interface DesktopMenuProps {
  navigationLinks: NavigationLink[];
  primaryAction: React.ReactNode;
  secondaryAction?: React.ReactNode;
}

export default function DesktopMenu({
  navigationLinks,
  primaryAction,
  secondaryAction,
}: DesktopMenuProps): JSX.Element {
  const navRef = useRef<HTMLElement>();

  const startNavigationLinks = useMemo(
    () =>
      navigationLinks.filter(
        ({ desktopPosition }) => desktopPosition === LinkPositions.START
      ),
    [navigationLinks]
  );

  const endNavigationLinks = useMemo(
    () =>
      navigationLinks.filter(
        ({ desktopPosition }) => desktopPosition === LinkPositions.END
      ),
    [navigationLinks]
  );

  return (
    <>
      <header
        ref={navRef}
        className="mx-auto hidden h-[72px] max-w-[1400px] items-center justify-between gap-4 px-6 py-4 laptop:flex"
      >
        <a
          className="-mt-2 w-[100px] flex items-center h-[29px] navbar-desktop:h-[35px] navbar-desktop:w-[120px] mr-12 navbar-desktop:mr-15"
          href={process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}
        >
          <Image
            src="/images/nocd-logo.svg"
            alt="Go to homepage"
            width={120}
            height={35}
            priority
          />
        </a>

        <nav className="flex flex-1 items-center gap-4 navbar-desktop:gap-6">
          <ul className="flex items-center gap-4 navbar-desktop:gap-6">
            {startNavigationLinks
              .filter(({ hide }) => !hide)
              .map(({ Component, id, title }) => (
                <li key={id}>
                  <Component className="text-14px navbar-desktop:text-16px">
                    {title}
                  </Component>
                </li>
              ))}
          </ul>

          <ul className="flex gap-4 navbar-desktop:gap-6 items-center">
            {endNavigationLinks
              .filter(({ hide }) => !hide)
              .map(({ Component, id, title }) => (
                <li key={id}>
                  <Component className="flex items-center space-x-2 text-14px navbar-desktop:text-16px">
                    {title}
                  </Component>
                </li>
              ))}
          </ul>
        </nav>

        <div className="flex items-center gap-4 ">
          {secondaryAction}
          {primaryAction}
        </div>
      </header>
    </>
  );
}
