import { useSession } from "@core/hooks/useSession";
import Button from "@core/ui/Button";
import Loader from "@core/ui/Loader";
import ModalSheet from "@core/ui/ModalSheet";
import { useMutateSubtypes } from "@features/user-profile/hooks/useMutateSubtypes";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { create } from "zustand";

import { FeedCategories, useFeedOptions } from "../../hooks/useFeedOptions";
import { useMutateFeedOptions } from "../../hooks/useMutateFeedOptions";
import { useUserFeedSettings } from "../../hooks/useUserFeedSettings";
import { FeedContentFilters, FeedTypes } from "../../types";
import ContentOptions from "./ContentOptions";
import FeedTypeOptions from "./FeedTypeOptions";
import MyTopicsOptions from "./MyTopicsOptions";
import { FeedSettingsFormValues } from "./types";

export const feedSettingsDialogStore = create<{
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}>((set) => ({
  isOpen: false,
  onClose: () => set({ isOpen: false }),
  onOpen: () => set({ isOpen: true }),
}));

interface FeedSettingsFormProps {
  initialFilters: FeedContentFilters[];
  initialFeedType: FeedTypes;
  topics: FeedCategories;
  onSuccess: () => void;
}

const FeedSettingsForm = ({
  initialFeedType,
  initialFilters,
  topics,
  onSuccess,
}: FeedSettingsFormProps) => {
  const { mutateAsync: mutateFeedOptions } = useMutateFeedOptions();
  const { mutateAsync: mutateSubtypes } = useMutateSubtypes();
  const qc = useQueryClient();

  const initialTopicIds = useMemo(() => {
    return Object.values(topics)
      .flatMap((item) => item.topics)
      .filter((item) => item.isSelected)
      .map((item) => item.id);
  }, [topics]);

  const { control, handleSubmit, register, formState, watch } =
    useForm<FeedSettingsFormValues>({
      defaultValues: {
        feedType: initialFeedType,
        showTriggerWarningPosts: !initialFilters.includes(
          FeedContentFilters.NO_TRIGGER_WARNING
        ),
        showMemberPosts: !initialFilters.includes(FeedContentFilters.NOCD),
        topicIds: initialTopicIds,
      },
    });

  const { isSubmitting } = formState;
  const watchedFeedType = watch("feedType");

  const onSubmit = handleSubmit(
    ({ feedType, showTriggerWarningPosts, showMemberPosts, topicIds }) => {
      const newUserFeedSettings = {
        feedType,
        filters: [
          showTriggerWarningPosts
            ? null
            : FeedContentFilters.NO_TRIGGER_WARNING,
          showMemberPosts ? null : FeedContentFilters.NOCD,
        ].filter(Boolean),
        topicIds,
      };

      return mutateFeedOptions(newUserFeedSettings)
        .then(() => mutateSubtypes(topicIds))
        .then(() => qc.invalidateQueries(["all-posts"]))
        .then(onSuccess);
    }
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="grid phone:grid-cols-2 tablet:grid-cols-3">
          <MyTopicsOptions
            key={`my-topics-subtypes-${watchedFeedType}`}
            isDisabled={isSubmitting || watchedFeedType !== FeedTypes.MY_TOPICS}
            register={register}
            topics={topics.subtypes.topics}
            title="Subtypes"
          />

          {Array.isArray(topics.community_filters?.topics) && (
            <MyTopicsOptions
              key={`my-topics-customize-${watchedFeedType}`}
              isDisabled={
                isSubmitting || watchedFeedType !== FeedTypes.MY_TOPICS
              }
              register={register}
              topics={topics.community_filters.topics}
              title="Customize"
            />
          )}

          <div>
            <FeedTypeOptions
              key={`feedTypeOptions-${watchedFeedType}`}
              control={control}
              isSubmitting={isSubmitting}
            />
            <ContentOptions
              key={`contentOptions-${watchedFeedType}`}
              register={register}
              isSubmitting={isSubmitting}
            />
          </div>
        </div>

        <div className="flex justify-center">
          <Button
            color="indigo"
            loading={isSubmitting}
            type="submit"
            className="w-full text-16px font-bold"
            rounded="lg"
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

const FeedSettingsDialog = (): JSX.Element => {
  const { isOpen, onClose } = feedSettingsDialogStore();
  const { data: session } = useSession();
  const { data: feedOptions, isLoading } = useFeedOptions();

  const { feedSettingsByUser } = useUserFeedSettings();
  const userFeedSettings = feedSettingsByUser[session?.user?.id];

  return (
    <ModalSheet onClose={onClose} size="xl" isOpen={isOpen}>
      {isLoading ? (
        <div className="flex justify-center items-center py-16 text-indigo-600 text-32px">
          <Loader />
        </div>
      ) : (
        <FeedSettingsForm
          initialFeedType={feedOptions?.feedType}
          initialFilters={userFeedSettings?.filters ?? []}
          topics={feedOptions?.categories}
          onSuccess={onClose}
        />
      )}
    </ModalSheet>
  );
};

export default FeedSettingsDialog;
