import { DateTime, Money } from "@core/types";

export enum Plans {
  INSURANCE = "Insurance",
  CASH_PAY = "Cash_pay",
}

export enum ClaimStatuses {
  INVOICED = "invoiced",
  MR_TRANSFERRED = "mr_transferred",
}

export interface BalanceCellProps extends Money {
  overrideText?: string;
  tooltipText?: string;
  variant?: "success" | "warning" | "danger";
}

export interface Overview {
  balance: Money;
  hasInsurance: boolean;
  paid?: Money | null;
}

export interface LineApplication {
  id: string | number;
  amount: Money;
  adjustmentType: string;
  appliedAt: DateTime;
  notes?: string;
}

export interface Progress {
  body: string;
  formattedDate: string;
  bulletHex: string;
}

export interface ServiceEncounter {
  title: string;
  cptCode: string;
  isRoot: boolean;
}

export interface Status {
  label: string;
  tooltipText: string;
  colorHex: string;
}

export interface Invoice {
  id: number;
  invoiceDate: DateTime;
  totalAmount: Money;
  amountPaid: Money;
  amountRemaining: BalanceCellProps;
  notes?: string;
  downloadable: boolean;
  lineApplications: LineApplication[];
  hasSuperbill?: boolean;
  claimStatus?: ClaimStatuses;
  plan?: Plans;
  progress?: Progress[];
  serviceEncounters?: ServiceEncounter[];
  status?: Status;
  waitingOnTotalAmountFromInsurance?: boolean;
  isCopay?: boolean;
  copayAmount?: Money;
}

export interface Appointment {
  sessionDate: DateTime;
  invoices: Invoice[];
  currency: string;
  id: string | number;
}

export type CoveragePlan = "cash_pay" | "insurance";

export interface BaseCoverage {
  startDate: DateTime;
  endDate: DateTime;
  id: number;
  planName: string;
  isActive: boolean;
}

export interface Insurance {
  id: number;
  provider: string;
  groupNumber: string;
  memberId: string;
  copay?: Money;
}

export interface CashPayCoverage extends BaseCoverage {
  plan: "cash_pay";
  rates: {
    duration30: Money;
    duration45: Money;
    duration60: Money;
    duration90: Money;
    currency: string;
  };
}

export interface InsuranceCoverage extends BaseCoverage {
  plan: "insurance";
  insurance: {
    primary: Insurance;
    secondary?: Insurance;
    tertiary?: Insurance;
  };
}

export type Coverage = CashPayCoverage | InsuranceCoverage;

export interface Charge {
  amount: Money;
  currency: string;
  createdAt: DateTime;
  id: number;
  notes?: string;
  receiptUrl: string;
}

export interface PaymentMethod {
  card: {
    last4: string;
    brand: string;
    exp_month: number;
    exp_year: number;
  };
  customer: string;
  id: string;
  isDefault: boolean;
  createdAt: DateTime;
}

export interface Settings {
  superbillEnabled: boolean;
}

export interface PaymentPlan {
  id: number;
  amount: Money;
  frequency: string;
}

export interface MemberStatus {
  isBookingEnabled: boolean;
  memberWarning: string;
  internalWarning: string;
  actionType?: string | null;
  dueDate?: string | null;
  balanceType?: string | null;
}
