import { useSession } from "@core/hooks/useSession";
import { getInfinitePostsQueryKey } from "@features/community-v2/hooks/useInfinitePosts";
import { useUserFeedSettings } from "@features/community-v2/hooks/useUserFeedSettings";
import MenuIcon from "@heroicons/react/solid/MenuIcon";
import XIcon from "@heroicons/react/solid/XIcon";
import cn from "classnames";
import Image from "next/image";
import Link, { LinkProps } from "next/link";
import { Router, useRouter } from "next/router";
import {
  forwardRef,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useQueryClient } from "react-query";

import { NavigationLink } from "./types";

interface NextLinkProps {
  children?: ReactNode;
  href: LinkProps["href"];
  className?: string;
}

const NextLink = forwardRef<HTMLAnchorElement, NextLinkProps>((props, ref) => {
  const { href, children, ...rest } = props;

  return (
    <Link href={href} passHref>
      <a {...rest} ref={ref}>
        {children}
      </a>
    </Link>
  );
});

NextLink.displayName = "NextLink";

interface MobileMenuProps {
  callToAction: ReactNode;
  isTherapyMember: boolean;
  isWebView: boolean;
  navigationLinks: NavigationLink[];
}

export default function MobileMenu({
  callToAction,
  isTherapyMember,
  isWebView,
  navigationLinks,
}: MobileMenuProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();
  const { data: session } = useSession();
  const queryClient = useQueryClient();

  const { feedSettingsByUser } = useUserFeedSettings();
  const userFeedSettings = feedSettingsByUser?.[session?.user?.id];
  const infinitePostsQueryKey = getInfinitePostsQueryKey(
    session?.accessToken,
    userFeedSettings
  );

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  // Close the menu on route change
  useEffect(() => {
    Router.events.on("routeChangeComplete", closeMenu);

    return () => {
      Router.events.off("routeChangeComplete", closeMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Prevent body scroll when opened
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.documentElement.style.overflow = isOpen ? "hidden" : "scroll";
    }
  }, [isOpen]);

  return (
    <nav className="tablet:hidden">
      <header className="flex h-[58px] max-w-[1072px] items-center justify-between px-4">
        <div className="w-18">
          <button
            type="button"
            onClick={() => setIsOpen((prev) => !prev)}
            className="focus:outline-none inline-flex items-center justify-center rounded-md p-2 text-gray-800 transition-colors hover:bg-teal-100 hover:text-teal-600 focus:ring-2 focus:ring-inset focus:ring-teal-600"
          >
            <span className="sr-only">Open main menu</span>
            {isOpen ? (
              <XIcon
                className="block h-6 w-6 transition-colors"
                aria-hidden="true"
              />
            ) : (
              <MenuIcon
                className="block h-6 w-6 transition-colors"
                aria-hidden="true"
              />
            )}
          </button>
        </div>

        <div className="flex flex-1 justify-center">
          <button
            type="button"
            className="h-[37px] w-[36px]"
            onClick={async () => {
              // We embed this web app in our mobile apps under a "member portal"
              // tab. When the app is accessed inside of a web view, the user
              // should not be able to navigate to the community. If the user is
              // a therapy member, their "homepage" should be therapy.
              if (isWebView || isTherapyMember) {
                return router.push("/therapy", undefined, { scroll: true });
              }

              await router.push("/community/posts", undefined, {
                scroll: true,
              });

              // The user is on the home page and they click the logo in the
              // navbar, refresh the main feed.
              if (router.route === "/community/posts") {
                await queryClient.invalidateQueries([infinitePostsQueryKey]);
              }

              return undefined;
            }}
          >
            <Image
              src="/images/nocd-logo-simple.svg"
              alt="Go to homepage"
              width={36}
              height={37}
              priority
            />
          </button>
        </div>

        <nav>{callToAction}</nav>
      </header>

      {isOpen && (
        <div className="top-[58px] overflow-y-scroll left-0 bottom-0 right-0 bg-white fixed space-y-1 px-4 pt-2 pb-3">
          {navigationLinks
            .filter(({ hide }) => !hide)
            .map(({ href, id, title, subLinks, subMenu }) =>
              !subLinks ? (
                <Fragment key={id}>
                  <NextLink
                    href={href}
                    className={cn(
                      "block rounded-md px-3 py-2 text-base font-medium transition-colors text-left",
                      href === router.pathname
                        ? "bg-teal-100 text-teal-600"
                        : "text-gray-800 hover:bg-gray-100"
                    )}
                  >
                    <div>{title}</div>
                  </NextLink>

                  {subMenu ? (
                    <div className="mt-2 text-14px">{subMenu}</div>
                  ) : null}
                </Fragment>
              ) : (
                <div
                  key={id}
                  className={cn(
                    "block rounded-md px-3 pt-2 text-base font-medium transition-colors text-left"
                  )}
                >
                  <div>{title}</div>
                  <div className="mt-2 text-14px">
                    <ul>
                      {subLinks.map((subLink) => (
                        <li key={`sublink-${subLink.id}`}>
                          <NextLink
                            key={id}
                            href={subLink.href}
                            className={cn(
                              "block rounded-md px-3 py-2 text-base font-medium transition-colors text-left",
                              href === router.pathname
                                ? "bg-teal-100 text-teal-600"
                                : "text-gray-800 hover:bg-gray-100"
                            )}
                          >
                            {subLink.title}
                          </NextLink>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {subMenu ? (
                    <div className="mt-2 text-14px">{subMenu}</div>
                  ) : null}
                </div>
              )
            )}
        </div>
      )}
    </nav>
  );
}
