import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
  UseQueryResult,
} from "react-query";

import { FeedTypes } from "../types";
import {
  FeedOptions,
  getQueryKey as getFeedOptionsQueryKey,
} from "./useFeedOptions";
import { UserFeedSettings, useUserFeedSettings } from "./useUserFeedSettings";

interface UserFeedSettingsWithTopicIdsAndFeedType extends UserFeedSettings {
  topicIds: string[];
  feedType: FeedTypes;
}

export const useMutateFeedOptions = (): UseMutationResult<
  unknown,
  Error,
  UserFeedSettingsWithTopicIdsAndFeedType
> => {
  const { data: session } = useSession();
  const { accessToken, deviceId } = session ?? {};
  const { updateFeedSettingsByUserId } = useUserFeedSettings();
  const queryClient = useQueryClient();

  return useMutation(
    ({ topicIds, filters, feedType }) => {
      return api.post(
        `/v2/community/filter_menu`,
        {
          feed_type: feedType,
          topic_ids: topicIds.join(","),
          filters: filters.join(","),
        },
        {
          headers: {
            "X-DeviceID": deviceId,
            Authorization: accessToken,
          },
        }
      );
    },
    {
      onSuccess: (_data, { feedType, topicIds, filters }) => {
        updateFeedSettingsByUserId({ filters }, session?.user?.id);

        // See if there's any data in the query cache
        const previousFeedOptions = queryClient.getQueryData(
          getFeedOptionsQueryKey(accessToken)
        );

        if (previousFeedOptions) {
          queryClient.setQueryData(
            getFeedOptionsQueryKey(accessToken),
            (oldData: UseQueryResult<FeedOptions>["data"]) => ({
              ...oldData,
              feedType,

              categories: Object.entries(oldData.categories).reduce(
                (acc, [key, data]) => {
                  const next = { ...data };
                  next.topics = next.topics.map((item) => ({
                    ...item,
                    isSelected: topicIds.includes(item.id),
                  }));
                  acc[key] = next;
                  return acc;
                },
                {}
              ),

              // categories: [
              //   {
              //     ...oldData.categories[0],

              //     topics: oldData.categories[0].topics.map((topic) => ({
              //       ...topic,
              //       isSelected: topicIds.includes(topic.id),
              //     })),
              //   },
              // ],
            })
          );
        }
      },
    }
  );
};
