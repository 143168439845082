import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import {
  DiscoverFeedPost,
  isSustainedConquerorCommunityPost,
  isTopCommunityPost,
  isTreatmentConquerorCommunityPost,
} from "@features/community-v2";
import { getMyPostsQueryKey } from "@features/community-v2/hooks/useMyPosts";
import { getPrivateDiscoverFeedQueryKey } from "@features/community-v2/hooks/usePrivateDiscoverFeed";
import {
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQueryClient,
  UseQueryResult,
} from "react-query";

import {
  isCommunityPost,
  PaginatedPosts,
  Post,
  PostActionIds,
  PostId,
  RootPostId,
} from "../types";
import { getInfinitePostsQueryKey } from "./useInfinitePosts";
import { getMyBookmarksQueryKey } from "./useMyBookmarks";
import { getPostQueryKey, GetPostResponse } from "./usePost";
import { useUserFeedSettings } from "./useUserFeedSettings";

type MutationVariables = {
  postId: PostId;
  actionId: string;
  isEnabled?: boolean;
  userId?: string;
  category?: string;
};

export const useMutatePostAction = (
  rootPostId: RootPostId
): UseMutationResult<unknown, Error, MutationVariables> => {
  const { data: session } = useSession();
  const { accessToken, deviceId } = session ?? {};

  const { feedSettingsByUser } = useUserFeedSettings();
  const userFeedSettings = feedSettingsByUser?.[session?.user?.id];
  const infinitePostsQueryKey = getInfinitePostsQueryKey(
    session?.accessToken,
    userFeedSettings
  );
  const myPostsQueryKey = getMyPostsQueryKey(session?.accessToken);
  const myBookmarksQueryKey = getMyBookmarksQueryKey(session?.accessToken);
  const privateDiscoverFeedQueryKey = getPrivateDiscoverFeedQueryKey(
    session?.accessToken
  );
  const queryClient = useQueryClient();

  return useMutation(
    ({ postId, category, actionId, isEnabled }) => {
      if (actionId === PostActionIds.LIKE_POST) {
        return isEnabled
          ? api.delete(`/v1/posts/${postId}/like`, {
              headers: {
                "X-DeviceID": deviceId,
                Authorization: accessToken,
              },
            })
          : api.post(`/v1/posts/${postId}/like`, undefined, {
              headers: {
                "X-DeviceID": deviceId,
                Authorization: accessToken,
              },
            });
      }

      if (actionId === PostActionIds.DELETE_POST) {
        return api.delete(`/v1/posts/${postId}`, {
          headers: {
            Authorization: accessToken,
          },
        });
      }

      if (actionId === PostActionIds.FLAG_POST) {
        return api.post(
          `/v1/posts/${postId}/flag`,
          {
            category,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );
      }

      if (actionId === PostActionIds.HIDE_POST) {
        return api.post(
          "/v1/community/hide_post",
          {
            post_id: postId,
            hide: true,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );
      }

      if (actionId === PostActionIds.BOOKMARK_POST) {
        return api.post(
          "/v1/community/bookmarks",
          {
            postID: postId,
            delete: false,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );
      }

      if (actionId === PostActionIds.UNBOOKMARK_POST) {
        return api.post(
          "/v1/community/bookmarks",
          {
            postID: postId,
            delete: true,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );
      }

      return api.post(
        `/v1/community/post_context_menu`,
        {
          id: actionId,
          enabled: isEnabled,
          post_id: postId,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
    },
    {
      onSuccess: (_data, { userId, isEnabled, actionId, postId }) => {
        // See if there's any data in the query cache
        const previousAllPosts = queryClient.getQueryData(
          infinitePostsQueryKey
        );
        const previousPost = queryClient.getQueryData(
          getPostQueryKey(rootPostId, accessToken)
        );
        const previousMyPosts = queryClient.getQueryData(myPostsQueryKey);
        const previousMyBookmarks =
          queryClient.getQueryData(myBookmarksQueryKey);

        const previousPrivateDiscoverFeed = queryClient.getQueryData(
          privateDiscoverFeedQueryKey
        );

        if (actionId === PostActionIds.BLOCK_USER) {
          // Remove all of the user's posts from the main feed
          if (previousAllPosts) {
            queryClient.setQueryData(
              infinitePostsQueryKey,
              (oldData: UseInfiniteQueryResult<PaginatedPosts>["data"]) => {
                const newPages = oldData.pages.map((page) => ({
                  ...page,
                  data: page.data.filter((post) => +post.user_id !== +userId),
                }));

                return {
                  ...oldData,
                  pages: newPages,
                };
              }
            );
          }

          if (previousPrivateDiscoverFeed) {
            queryClient.setQueryData(
              privateDiscoverFeedQueryKey,
              (
                oldData: UseQueryResult<{ posts: DiscoverFeedPost[] }>["data"]
              ) => {
                return {
                  posts: oldData.posts.filter((post) => {
                    if (
                      isSustainedConquerorCommunityPost(post) ||
                      isTreatmentConquerorCommunityPost(post) ||
                      isTopCommunityPost(post) ||
                      isCommunityPost(post)
                    ) {
                      return +post.data.user_id !== +userId;
                    }

                    return true;
                  }),
                };
              }
            );
          }

          // Hide the user's replies on the current post thread
          if (previousPost && +rootPostId !== +postId) {
            queryClient.setQueryData(
              getPostQueryKey(rootPostId, accessToken),
              (oldData: UseQueryResult<GetPostResponse>["data"]) => {
                return {
                  ...oldData,
                  replies: oldData.replies.filter(
                    (reply) => +reply.user_id !== +userId
                  ),
                };
              }
            );
          }
        }

        if (
          actionId === PostActionIds.DELETE_POST ||
          actionId === PostActionIds.HIDE_POST ||
          actionId === PostActionIds.FLAG_POST
        ) {
          // Remove the post from the main feed
          if (previousAllPosts) {
            queryClient.setQueryData(
              infinitePostsQueryKey,
              (oldData: UseInfiniteQueryResult<PaginatedPosts>["data"]) => {
                const newPages = oldData?.pages?.map((page) => ({
                  ...page,
                  data: page.data.filter((post) => +post.id !== +postId),
                }));

                return {
                  ...oldData,
                  pages: newPages,
                };
              }
            );
          }

          if (previousPrivateDiscoverFeed) {
            queryClient.setQueryData(
              privateDiscoverFeedQueryKey,
              (
                oldData: UseQueryResult<{ posts: DiscoverFeedPost[] }>["data"]
              ) => {
                return {
                  posts: oldData.posts.filter((post) => {
                    if (
                      isSustainedConquerorCommunityPost(post) ||
                      isTreatmentConquerorCommunityPost(post) ||
                      isTopCommunityPost(post) ||
                      isCommunityPost(post)
                    ) {
                      return +post.data.id !== +postId;
                    }

                    return true;
                  }),
                };
              }
            );
          }

          // Remove the post from the "My Posts" page
          if (previousMyPosts) {
            queryClient.setQueryData(
              myPostsQueryKey,
              (oldData: UseQueryResult<Post[]>["data"]) => {
                return oldData.filter((post) => +post.id !== +postId);
              }
            );
          }

          // Remove the post from the "My Bookmarks" page
          if (previousMyBookmarks) {
            queryClient.setQueryData(
              myBookmarksQueryKey,
              (
                oldData: UseQueryResult<{
                  posts: Post[];
                  replies: Post[];
                }>["data"]
              ) => {
                return {
                  posts: oldData.posts.filter((post) => +post.id !== +postId),
                  replies: oldData.replies.filter(
                    (reply) => +reply.id !== +postId
                  ),
                };
              }
            );
          }

          // Remove the reply from the post thread
          if (previousPost && +rootPostId !== +postId) {
            queryClient.setQueryData(
              getPostQueryKey(rootPostId, accessToken),
              (oldData: UseQueryResult<GetPostResponse>["data"]) => {
                return {
                  ...oldData,
                  replies: oldData.replies.filter(
                    (reply) => +reply.id !== +postId
                  ),
                };
              }
            );
          }
        }

        if (actionId === PostActionIds.LIKE_POST) {
          // Update the like count for the post in the main feed
          if (+postId === +rootPostId && previousAllPosts) {
            queryClient.setQueryData(
              infinitePostsQueryKey,
              (oldData: UseInfiniteQueryResult<PaginatedPosts>["data"]) => {
                const newPages = oldData.pages.map((page) => ({
                  ...page,
                  data: page.data.map((post) => {
                    if (+post.id === +postId) {
                      return {
                        ...post,
                        is_liked: !isEnabled,
                        likes: isEnabled ? post.likes - 1 : post.likes + 1,
                      };
                    }

                    return post;
                  }),
                }));

                return {
                  ...oldData,
                  pages: newPages,
                };
              }
            );
          }

          if (previousPrivateDiscoverFeed) {
            queryClient.setQueryData(
              privateDiscoverFeedQueryKey,
              (
                oldData: UseQueryResult<{ posts: DiscoverFeedPost[] }>["data"]
              ) => {
                return {
                  posts: oldData.posts.map((item) => {
                    if (
                      (isSustainedConquerorCommunityPost(item) ||
                        isTreatmentConquerorCommunityPost(item) ||
                        isTopCommunityPost(item) ||
                        isCommunityPost(item)) &&
                      +item.data.id === +postId
                    ) {
                      return {
                        ...item,
                        data: {
                          ...item.data,
                          is_liked: !isEnabled,
                          likes: isEnabled
                            ? item.data.likes - 1
                            : item.data.likes + 1,
                        },
                      };
                    }

                    return item;
                  }),
                };
              }
            );
          }

          // Update the like count for the post in the "My Posts" page
          if (previousMyPosts) {
            queryClient.setQueryData(
              myPostsQueryKey,
              (oldData: UseQueryResult<Post[]>["data"]) => {
                const newPosts = oldData.map((post) => {
                  if (+postId === +post.id) {
                    return {
                      ...post,
                      is_liked: !isEnabled,
                      likes: isEnabled ? post.likes - 1 : post.likes + 1,
                    };
                  }
                  return post;
                });
                return newPosts;
              }
            );
          }

          // Update the like count for the post in the "My Bookmarks" page
          if (previousMyBookmarks) {
            queryClient.setQueryData(
              myBookmarksQueryKey,
              (
                oldData: UseQueryResult<{
                  posts: Post[];
                  replies: Post[];
                }>["data"]
              ) => {
                const newPosts = {
                  posts: oldData.posts.map((post) => {
                    if (+postId === +post.id) {
                      return {
                        ...post,
                        is_liked: !isEnabled,
                        likes: isEnabled ? post.likes - 1 : post.likes + 1,
                      };
                    }
                    return post;
                  }),
                  replies: oldData.replies.map((reply) => {
                    if (+postId === +reply.id) {
                      return {
                        ...reply,
                        is_liked: !isEnabled,
                        likes: isEnabled ? reply.likes - 1 : reply.likes + 1,
                      };
                    }
                    return reply;
                  }),
                };
                return newPosts;
              }
            );
          }

          // Update the like count in the post thread
          if (previousPost) {
            queryClient.setQueryData(
              getPostQueryKey(rootPostId, accessToken),
              (oldData: UseQueryResult<GetPostResponse>["data"]) => {
                if (+postId === +rootPostId) {
                  return {
                    ...oldData,
                    post: {
                      ...oldData.post,
                      is_liked: !isEnabled,
                      likes: isEnabled
                        ? oldData.post.likes - 1
                        : oldData.post.likes + 1,
                    },
                  };
                }

                return {
                  ...oldData,
                  replies: oldData.replies.map((reply) => {
                    if (+reply.id === +postId) {
                      return {
                        ...reply,
                        is_liked: !isEnabled,
                        likes: isEnabled ? reply.likes - 1 : reply.likes + 1,
                      };
                    }

                    return reply;
                  }),
                };
              }
            );
          }
        }

        if (
          [PostActionIds.BOOKMARK_POST, PostActionIds.UNBOOKMARK_POST].includes(
            actionId as PostActionIds
          )
        ) {
          // Update the bookmark status for the post in the main feed
          if (+postId === +rootPostId && previousAllPosts) {
            queryClient.setQueryData(
              infinitePostsQueryKey,
              (oldData: UseInfiniteQueryResult<PaginatedPosts>["data"]) => {
                const newPages = oldData.pages.map((page) => ({
                  ...page,
                  data: page.data.map((post) => {
                    if (+post.id === +postId) {
                      return {
                        ...post,
                        isBookmarked: actionId === PostActionIds.BOOKMARK_POST,
                      };
                    }

                    return post;
                  }),
                }));

                return {
                  ...oldData,
                  pages: newPages,
                };
              }
            );
          }

          if (+postId === +rootPostId && previousPrivateDiscoverFeed) {
            queryClient.setQueryData(
              privateDiscoverFeedQueryKey,
              (
                oldData: UseQueryResult<{ posts: DiscoverFeedPost[] }>["data"]
              ) => {
                return {
                  posts: oldData.posts.map((item) => {
                    if (
                      (isSustainedConquerorCommunityPost(item) ||
                        isTreatmentConquerorCommunityPost(item) ||
                        isTopCommunityPost(item) ||
                        isCommunityPost(item)) &&
                      +item.data.id === +postId
                    ) {
                      return {
                        ...item,
                        data: {
                          ...item.data,
                          isBookmarked:
                            actionId === PostActionIds.BOOKMARK_POST,
                        },
                      };
                    }

                    return item;
                  }),
                };
              }
            );
          }

          // Refetch my bookmarks query
          if (previousMyBookmarks) {
            void queryClient.invalidateQueries(myBookmarksQueryKey);
          }

          // Update the bookmark status in the post thread
          if (previousPost) {
            queryClient.setQueryData(
              getPostQueryKey(rootPostId, accessToken),
              (oldData: UseQueryResult<GetPostResponse>["data"]) => {
                if (+postId === +rootPostId) {
                  return {
                    ...oldData,
                    post: {
                      ...oldData.post,
                      isBookmarked: actionId === PostActionIds.BOOKMARK_POST,
                    },
                  };
                }

                return {
                  ...oldData,
                  replies: oldData.replies.map((reply) => {
                    if (+reply.id === +postId) {
                      return {
                        ...reply,
                        isBookmarked: actionId === PostActionIds.BOOKMARK_POST,
                      };
                    }

                    return reply;
                  }),
                };
              }
            );
          }
        }
      },
    }
  );
};
