import Button from "@core/ui/Button";
import ModalSheet from "@core/ui/ModalSheet";
import { XIcon } from "@heroicons/react/solid";
import { useCallback, useEffect, useRef } from "react";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface SensitiveTopicStore {
  hasBeenClosed: boolean;
  setHasBeenClosed: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const useSensitiveTopicStore = create<SensitiveTopicStore>()(
  persist(
    (set) => ({
      hasBeenClosed: false,
      setHasBeenClosed: () => set({ hasBeenClosed: true }),
      isOpen: false,
      setIsOpen: (isOpen) => set({ isOpen }),
    }),
    {
      name: "community-sensitive-topics", // name of the item in the storage (must be unique)
      partialize: (state) => ({
        hasBeenClosed: state.hasBeenClosed,
      }),
    }
  )
);

export default function SensitiveTopicDialog() {
  const { hasBeenClosed, setHasBeenClosed, isOpen, setIsOpen } =
    useSensitiveTopicStore();
  const defaultOpenWithDelay = true;

  const alreadyOpened = useRef(hasBeenClosed);

  const openModal = useCallback(() => {
    alreadyOpened.current = true;
    return setIsOpen(true);
  }, [setIsOpen]);

  useEffect(() => {
    if (alreadyOpened.current) {
      return undefined;
    }

    if (!defaultOpenWithDelay) {
      return undefined;
    }

    const timeoutId = setTimeout(() => {
      if (alreadyOpened.current || hasBeenClosed) {
        return;
      }

      openModal();
    }, 750);

    return () => clearTimeout(timeoutId);
  }, [defaultOpenWithDelay, openModal, hasBeenClosed]);

  const handleMarkAsSeen = useCallback(() => {
    setIsOpen(false);
    setHasBeenClosed();
  }, [setHasBeenClosed, setIsOpen]);

  return (
    <ModalSheet
      disablePadding
      isOpen={isOpen}
      onClose={() => {}}
      disableCloseButton
      size="sm"
      classNames={{
        overlay: "bg-opacity-60",
      }}
    >
      <button
        type="button"
        onClick={handleMarkAsSeen}
        className="absolute top-4 right-4 focus:outline-none"
      >
        <span className="sr-only">close</span>

        <XIcon className="w-6 h-6 text-gray-900" />
      </button>

      <div className="px-6 pt-4 pb-6 text-gray-900">
        <p className="text-24px font-bold text-center text-indigo-600 leading-tight">
          A message from our <br className="tablet:hidden" /> clinical team
        </p>

        <p className="text-center my-4">
          1 in 40 people experience OCD, yet it&apos;s commonly misunderstood.
          Therapy members and OCD Conquerors in our community are here to
          provide support and understanding throughout your journey.
        </p>

        <div className="p-4 bg-gray-100 rounded-xl">
          <div className="flex items-center gap-1">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <rect
                  width="20"
                  height="20"
                  transform="translate(0.5)"
                  fill="#F4F5F7"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.7438 4.04011C9.52428 2.6533 11.4758 2.6533 12.2556 4.04011L17.2257 12.8797C18.0055 14.2665 17.0297 16 15.4695 16H5.52997C3.96968 16 2.99459 14.2665 3.7744 12.8797L8.74312 4.04011H8.7438ZM10.5 7.67842C10.6345 7.67842 10.7634 7.73321 10.8584 7.83074C10.9535 7.92827 11.0069 8.06055 11.0069 8.19847V10.7988C11.0069 10.9367 10.9535 11.069 10.8584 11.1665C10.7634 11.264 10.6345 11.3188 10.5 11.3188C10.3656 11.3188 10.2367 11.264 10.1417 11.1665C10.0466 11.069 9.99324 10.9367 9.99324 10.7988V8.19847C9.99324 8.06055 10.0466 7.92827 10.1417 7.83074C10.2367 7.73321 10.3656 7.67842 10.5 7.67842ZM10.5 13.399C10.6345 13.399 10.7634 13.3442 10.8584 13.2467C10.9535 13.1492 11.0069 13.0169 11.0069 12.879C11.0069 12.741 10.9535 12.6088 10.8584 12.5112C10.7634 12.4137 10.6345 12.3589 10.5 12.3589C10.3656 12.3589 10.2367 12.4137 10.1417 12.5112C10.0466 12.6088 9.99324 12.741 9.99324 12.879C9.99324 13.0169 10.0466 13.1492 10.1417 13.2467C10.2367 13.3442 10.3656 13.399 10.5 13.399Z"
                  fill="#3D4353"
                />
              </svg>
            </span>
            <span className="font-bold"> Please note: </span>
            <br />
          </div>
          <p>
            OCD often involves uncomfortable intrusive thoughts, so mature and
            taboo topics may arise in community discussions.
          </p>
        </div>

        <Button
          onClick={handleMarkAsSeen}
          className="font-semibold focus:outline-none focus:ring-0 block mt-8 w-full"
        >
          Got it
        </Button>
      </div>
    </ModalSheet>
  );
}
