import IntakeCallSchedulerModal from "@core/scheduler-modals/IntakeCallSchedulerModal";
import Button from "@core/ui/Button";
import { useAuthenticationModal } from "@features/auth/hooks/useAuthenticationModal";
import { TopicButtons } from "@features/community-v2/components/TopicSidebar";
import cn from "classnames";
import { throttle } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";

import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import { LinkPositions, NavigationLink } from "./types";

const CTA_BUTTON_LABEL = "Join";

interface GetNavigationLinksArgs {
  onClickLogIn: () => void;
}

const getNavigationLinks = ({
  onClickLogIn,
}: GetNavigationLinksArgs): NavigationLink[] => {
  return [
    {
      id: "find-a-therapist",
      title: "Find a therapist",
      desktopPosition: LinkPositions.START,
      Component: ({ children, className }) => (
        <a
          href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/therapists`}
          className={cn(
            "block transition-colors hover:text-teal-600",
            className
          )}
        >
          {children}
        </a>
      ),
    },
    {
      id: "why-nocd",
      title: "Why NOCD?",
      desktopPosition: LinkPositions.START,
      Component: ({ className, children }) => (
        <a
          href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/why-nocd`}
          className={cn(
            "block transition-colors hover:text-teal-600",
            className
          )}
        >
          {children}
        </a>
      ),
    },
    {
      id: "learn-about-pcd",
      title: "Learn about OCD",
      desktopPosition: LinkPositions.START,
      Component: ({ children, className }) => (
        <a
          href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/learn/blog`}
          className={cn(
            "block transition-colors hover:text-teal-600",
            className
          )}
        >
          {children}
        </a>
      ),
    },
    {
      id: "community",
      title: "Community",
      desktopPosition: LinkPositions.START,
      Component: ({ children, className }) => (
        <a
          href="/community/posts"
          className={cn(
            "w-full text-left transition-colors hover:text-teal-600",
            className
          )}
        >
          {children}
        </a>
      ),
    },
    {
      id: "reviews",
      title: "Reviews",
      desktopPosition: LinkPositions.START,
      Component: ({ children, className }) => (
        <a
          href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/reviews`}
          className={cn(
            "w-full text-left transition-colors hover:text-teal-600",
            className
          )}
        >
          {children}
        </a>
      ),
    },
    {
      id: "for-providers",
      title: "For providers",
      desktopPosition: LinkPositions.START,
      Component: ({ children, className }) => (
        <a
          href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/provider-resources`}
          className={cn(
            "w-full text-left transition-colors hover:text-teal-600",
            className
          )}
        >
          {children}
        </a>
      ),
    },
    {
      id: "community-filters",
      title: "Community filters",
      desktopPosition: LinkPositions.NONE,
      Component: () => (
        <div className="py-2">
          <TopicButtons />
        </div>
      ),
    },
    {
      id: "log-in",
      title: "Log in",
      desktopPosition: LinkPositions.NONE,
      Component: ({ children, className }) => (
        <button
          type="button"
          onClick={onClickLogIn}
          className={cn(
            "w-full text-left transition-colors hover:text-teal-600",
            className
          )}
        >
          {children}
        </button>
      ),
    },
  ];
};

const getNavShadowClasses = (
  hideShadowOnMobile: boolean,
  hideShadowOnDesktop: boolean,
  isWindowScrolled: boolean
) => {
  if (hideShadowOnMobile && hideShadowOnDesktop) {
    return "shadow-none";
  }

  if (hideShadowOnMobile && !hideShadowOnDesktop) {
    if (isWindowScrolled) {
      return " tablet:shadow-navbar-scrolled";
    }
  }

  if (!hideShadowOnMobile && hideShadowOnDesktop) {
    if (isWindowScrolled) {
      return "shadow-navbar-scrolled tablet:shadow-none";
    }
  }

  if (!hideShadowOnMobile && !hideShadowOnDesktop) {
    if (isWindowScrolled) {
      return "shadow-navbar-scrolled";
    }
  }

  return "shadow-none";
};

export default function UnauthenticatedNavBar({
  hideShadowOnMobile = false,
  hideShadowOnDesktop = false,
}: {
  hideShadowOnMobile?: boolean;
  hideShadowOnDesktop?: boolean;
}): JSX.Element {
  const { openModal } = useAuthenticationModal();
  const [isSchedulerModalOpen, setIsSchedulerModalOpen] =
    useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isWindowScrolled, setIsWindowScrolled] = useState<boolean>(false);
  const Router = useRouter();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsWindowScrolled(false);
      } else {
        setIsWindowScrolled(true);
      }
    };

    const throttledHandleScroll = throttle(handleScroll, 500);

    window.addEventListener("scroll", throttledHandleScroll);

    return () => window.removeEventListener("scroll", throttledHandleScroll);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMobileMenuOpen(false);
  }, []);

  useEffect(() => {
    Router.events.on("routeChangeComplete", closeMenu);

    return () => {
      Router.events.off("routeChangeComplete", closeMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigationLinks = useMemo(
    () =>
      getNavigationLinks({
        onClickLogIn: () => {
          closeMenu();
          return openModal({
            redirectAfterAuthentication: true,
          });
        },
      }),
    [closeMenu, openModal]
  );

  const handleOpenSchedulerModal = useCallback(
    () => setIsSchedulerModalOpen(true),
    []
  );

  const handleCloseSchedulerModal = useCallback(
    () => setIsSchedulerModalOpen(false),
    []
  );

  const handleToggleSubMenu = useCallback(
    () => setIsMobileMenuOpen((previousState) => !previousState),
    []
  );

  return (
    <div
      className={cn(
        "duration-600 fixed left-0 right-0 top-0 z-20 bg-white transition-shadow font-manrope",
        getNavShadowClasses(
          hideShadowOnMobile,
          hideShadowOnDesktop,
          isWindowScrolled
        )
      )}
    >
      <DesktopMenu
        navigationLinks={navigationLinks}
        primaryAction={
          <Button
            rounded="full"
            className="px-[0.875em] py-[0.75em] text-14px navbar-desktop:text-16px"
            disablePadding
            disableMinHeight
            color="indigo"
            onClick={() => openModal()}
          >
            {CTA_BUTTON_LABEL}
          </Button>
        }
        secondaryAction={
          <Button
            color="light-gray"
            rounded="full"
            className="px-[0.875em] py-[0.75em] text-14px navbar-desktop:text-16px"
            disablePadding
            disableMinHeight
            onClick={() => openModal()}
          >
            Log in
          </Button>
        }
      />

      <MobileMenu
        callToAction={
          <Button
            rounded="full"
            className="block !h-[32px] !min-h-[32px] !w-[74px] text-14px font-medium"
            disablePadding
            color="indigo"
            onClick={() => openModal()}
          >
            {CTA_BUTTON_LABEL}
          </Button>
        }
        ctaButtonLabel="Book a free call"
        isOpen={isMobileMenuOpen}
        navigationLinks={navigationLinks}
        onClickCTA={handleOpenSchedulerModal}
        onToggleMenu={handleToggleSubMenu}
      />

      <IntakeCallSchedulerModal
        key={`scheduler-${String(isSchedulerModalOpen)}`}
        isOpen={isSchedulerModalOpen}
        source="web_app_navbar_cta"
        handleClose={handleCloseSchedulerModal}
      />
    </div>
  );
}
