import useModalWithDelayedCallback from "@core/hooks/useModalWithDelayedCallback";
import { useSession } from "@core/hooks/useSession";
import useUser from "@core/hooks/useUser";
import Avatar from "@core/ui/Avatar";
import Loader from "@core/ui/Loader";
import Modal from "@core/ui/Modal";
import Switch from "@core/ui/Switch";
import Textarea from "@core/ui/Textarea";
import { useAuthenticationModal } from "@features/auth/hooks/useAuthenticationModal";
import useIsConquerorPost from "@features/community-v2/hooks/useIsConquerorPost";
import {
  CheckIcon,
  ChevronDownIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/solid";
import cn from "classnames";
import { noop } from "lodash/fp";
import Image from "next/image";
import { FC, useCallback, useMemo, useRef, useState } from "react";

import UnsavedChangesWatcherCommunity from "../../UnsavedChangesWatcherCommunity";
import useNewPostController from "../hooks/useNewPostController";
import useReassuranceCheck from "../hooks/useReassuranceCheck";
import ReassuranceModal from "./ReassuranceModal";
import UploadImage from "./UploadImage";

const ConquerorHeading: FC<{ variant: "default" | "sustained" }> = ({
  variant,
}) => {
  if (variant === "sustained") {
    return (
      <div className="text-center mx-auto max-w-[27rem] mb-4 space-y-4">
        <p className="text-24px font-bold">
          6 months of strength, we&apos;re here to help you keep going
        </p>

        <p className="text-14px">
          Reflect on your journey and share this pivotal milestone with the NOCD
          Community.
        </p>
      </div>
    );
  }
  return (
    <div className="text-center mx-auto max-w-[27rem] mb-4 pt-4 space-y-4">
      <p className="text-24px font-bold">You&apos;ve come so far!</p>

      <p className="text-14px">
        Write a Community post to celebrate and reflect on this important
        milestone and to give others hope.
      </p>
    </div>
  );
};

const FALLBACK_AVATAR_URL_FOR_UNAUTHENTICATED_USERS =
  "https://assets.treatmyocd.com/images/avatars/avatar_0_circle.png";

const getPlaceHolderText = (
  isConquerorPost: boolean,
  isSustainedConqeuror: boolean
) => {
  if (isConquerorPost) {
    if (isSustainedConqeuror) {
      return "Since conquering OCD, what can you do now?";
    }
    return "How do you see your life as being different from when you first started Therapy?";
  }
  return "Share details so the community can respond";
};

interface NewPostCardProps {
  className?: string;
  onSuccess?: () => void;
}

export default function NewPostCard({
  className,
  onSuccess = noop,
}: NewPostCardProps): JSX.Element {
  const { data: session } = useSession();
  const { data: user } = useUser();
  const { openModal } = useAuthenticationModal();
  const { data: reassuranceCheckData } = useReassuranceCheck(
    session?.accessToken ?? ""
  );
  const [hasReassuranceModalOpened, setHasReassuranceModalOpened] =
    useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const {
    isOpen: isReassuranceCheckOpen,
    close: closeReassuranceCheck,
    open: openReassuranceCheck,
  } = useModalWithDelayedCallback();

  const closeReassuranceModalWithoutAccepting = useCallback(() => {
    closeReassuranceCheck();
    formRef.current.blur();
    setHasReassuranceModalOpened(false);
  }, [closeReassuranceCheck]);

  const shouldOpenReassuranceModal = useMemo(
    () =>
      !!(
        reassuranceCheckData?.prompt_for_initial_reassurance_pledge ||
        reassuranceCheckData?.prompt_for_reassurance_pledge
      ) &&
      !isReassuranceCheckOpen &&
      !hasReassuranceModalOpened,
    [reassuranceCheckData, isReassuranceCheckOpen, hasReassuranceModalOpened]
  );

  const handleSuccess = useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  const {
    topics,
    isCommunityGroupModalOpen,
    setIsCommunityGroupModalOpen,
    isSubtypeModalOpen,
    setIsSubtypeModalOpen,
    handleImageUploaded,
    handleClickTopic,
    handleSubmit,
    handleSubmitConquerorPost,
    handleSubmitSustainedConquerorPost,
    handleImageDeleted,
    selectedTopicIds,
    form,
  } = useNewPostController(handleSuccess);

  const selectedSubtypes = useMemo(
    () =>
      topics.subtypes?.filter((subtype) =>
        selectedTopicIds.includes(subtype.id)
      ),
    [selectedTopicIds, topics.subtypes]
  );

  const selectedCommunityGroups = useMemo(
    () =>
      topics.communityGroups?.filter((subtype) =>
        selectedTopicIds.includes(subtype.id)
      ),
    [selectedTopicIds, topics.communityGroups]
  );

  const {
    isConquerorPost,
    isConquerorPostEnabled,
    setIsConquerorPost,
    variant,
  } = useIsConquerorPost();

  const { register, formState, control, watch } = form;
  const { isValid, isSubmitting, isDirty } = formState;

  let onSubmit;
  if (isConquerorPost) {
    if (variant === "sustained") {
      onSubmit = form.handleSubmit(handleSubmitSustainedConquerorPost);
    } else {
      onSubmit = form.handleSubmit(handleSubmitConquerorPost);
    }
  } else {
    onSubmit = form.handleSubmit(handleSubmit);
  }
  const titleValue = watch("title");

  return (
    <>
      <div
        className={cn(className, "rounded-2xl bg-white tablet:p-6 flex-col")}
      >
        {isConquerorPost ? <ConquerorHeading variant={variant} /> : null}

        <div className="space-y-4">
          <div className="flex space-x-4 items-center">
            <div className="flex-shrink-0 overflow-hidden rounded-full">
              <Avatar
                src={
                  user?.avatarUrl ??
                  FALLBACK_AVATAR_URL_FOR_UNAUTHENTICATED_USERS
                }
                alt={user?.communityHandle}
              />
            </div>

            <span>{user?.communityHandle}</span>
          </div>

          <div
            className="flex-1"
            onClick={
              session
                ? undefined
                : (event) => {
                    event.stopPropagation();
                    return openModal({
                      redirectAfterAuthentication: false,
                    });
                  }
            }
          >
            <div className="relative">
              <form
                ref={formRef}
                id="new-post-card"
                className={cn("space-y-3.5", !session && "pointer-events-none")}
                onSubmit={onSubmit}
              >
                <div className="block w-full rounded-2xl border border-indigo-600">
                  <div className="relative">
                    {!titleValue ? (
                      <label
                        htmlFor="post-title"
                        className="absolute top-[0.75em] left-4 text-lg font-semibold text-[#79798A] cursor-text pointer-events-none"
                      >
                        A question or topic for your post
                        <span className="text-red-600">*</span>
                      </label>
                    ) : null}
                    <input
                      id="post-title"
                      className="mt-[0.75em] w-full px-4 text-lg font-bold"
                      placeholder=""
                      {...register("title")}
                    />
                  </div>

                  <div className="px-4 flex gap-2 text-12px py-1">
                    {topics?.communityGroups ? (
                      <button
                        type="button"
                        className="bg-[#f0f5f8] rounded-lg py-1 px-2 flex items-center gap-1"
                        onClick={() => setIsCommunityGroupModalOpen(true)}
                      >
                        {!selectedCommunityGroups?.length ? (
                          <PlusIcon className="w-4 h-4" />
                        ) : null}

                        <span>
                          {selectedCommunityGroups?.length
                            ? selectedCommunityGroups[0].title
                            : "Community groups"}
                        </span>

                        {selectedCommunityGroups?.length ? (
                          <ChevronDownIcon className="w-4 h-4" />
                        ) : null}
                      </button>
                    ) : null}

                    {topics?.subtypes ? (
                      <button
                        type="button"
                        className="bg-[#f0f5f8] rounded-lg py-1 px-2 flex items-center gap-1"
                        onClick={() => setIsSubtypeModalOpen(true)}
                      >
                        {!selectedSubtypes?.length ? (
                          <PlusIcon className="w-4 h-4" />
                        ) : null}

                        <span>
                          {selectedSubtypes?.length
                            ? selectedSubtypes[0].title
                            : "Subtype"}
                        </span>

                        {selectedSubtypes?.length ? (
                          <ChevronDownIcon className="w-4 h-4" />
                        ) : null}
                      </button>
                    ) : null}
                  </div>

                  <label htmlFor="postBody">
                    <Textarea
                      id="postBody"
                      hideLabel
                      classes={{
                        input:
                          "!border-transparent !ring-transparent px-4 pb-[0.75rem] pt-[0.25rem]",
                      }}
                      label="Share details so the community can respond"
                      disabled={!session}
                      variant="outlined"
                      placeholder={getPlaceHolderText(
                        isConquerorPost,
                        variant === "sustained"
                      )}
                      minRows={3}
                      maxRows={5}
                      disablePadding
                      {...register("body")}
                    />
                  </label>

                  <div className="px-3">
                    <div className="w-full pb-2 pt-3">
                      {!isConquerorPost ? (
                        <div>
                          {isConquerorPostEnabled && (
                            <button
                              onClick={() => setIsConquerorPost(true)}
                              type="button"
                              className="text-14px mb-4 flex w-full items-center justify-between rounded-lg bg-gradient-to-r from-[#E7F1FE] to-white px-6 py-3"
                            >
                              <p className="font-bold text-[#545CEA]">
                                ADD MILESTONE BANNER
                              </p>

                              <PlusIcon className="h-4 w-4" />
                            </button>
                          )}

                          <div className="flex items-center justify-between mt-4 mx-2">
                            <UploadImage
                              onUpload={handleImageUploaded}
                              control={control}
                              onDeleteUpload={handleImageDeleted}
                            />

                            <button
                              type="submit"
                              disabled={isSubmitting || !isValid}
                              className="group disabled:cursor-not-allowed transition-all duration-300"
                            >
                              <dl>
                                <dt className="pb-1.5">
                                  <p
                                    className={cn(
                                      "text-15px font-bold group-disabled:text-[#CBD5E1] leading-[19.95px] text-indigo-600"
                                    )}
                                  >
                                    {isSubmitting ? <Loader /> : "Post"}
                                  </p>
                                </dt>

                                <dd className="sr-only">Share</dd>
                              </dl>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="overflow-hidden pl-22 tablet:pl-28 tablet:py-4 tablet:pr-6 flex items-center justify-between rounded-lg py-1 pr-4 relative">
                          {variant === "default" ? (
                            <>
                              <Image
                                src="/images/community/conqueror-preview.svg"
                                layout="fill"
                                objectFit="cover"
                                objectPosition="left center"
                                alt=""
                                className="z-0"
                              />

                              <p className="font-bold text-black relative z-1">
                                I received my OCD Conqueror badge!
                              </p>

                              <XIcon
                                onClick={() => setIsConquerorPost(false)}
                                className="tablet:ml-12 ml-6 h-5 w-5 cursor-pointer relative z-1"
                              />
                            </>
                          ) : (
                            <>
                              <Image
                                src="/images/community/sustained-conqueror-preview.png"
                                layout="fill"
                                objectFit="cover"
                                objectPosition="left center"
                                alt=""
                                className="z-0"
                              />

                              <p className="font-bold text-white z-1 relative">
                                I&apos;ve been an OCD Conqueror for 6+ months!
                              </p>

                              <XIcon
                                onClick={() => setIsConquerorPost(false)}
                                className="tablet:ml-12 ml-6 h-5 w-5 cursor-pointer relative z-1 text-white"
                              />
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="text-14px tablet:text-16px flex items-center justify-end space-x-4 rounded-lg">
                  <div className="leading-tight text-opacity-80">
                    Does your post contain content that might be a trigger for
                    other members?
                  </div>

                  <Switch.Controlled
                    name="hasTrigger"
                    control={control}
                    unCheckedChildren="No"
                  />
                </div>
                <UnsavedChangesWatcherCommunity isDirty={isDirty} />
              </form>

              {shouldOpenReassuranceModal ? (
                <button
                  type="button"
                  className="w-full h-full opacity-0 absolute top-0 left-0"
                  onClick={() => {
                    openReassuranceCheck();
                    setHasReassuranceModalOpened(true);
                  }}
                >
                  Open reassurance modal
                </button>
              ) : null}
            </div>

            {session && isSubtypeModalOpen ? (
              <Modal
                onClose={() => setIsSubtypeModalOpen(false)}
                isOpen={isSubtypeModalOpen}
              >
                <Modal.CloseButton
                  onClick={() => setIsSubtypeModalOpen(false)}
                />
                <div className="flex flex-col gap-3 px-6 tablet:text-center">
                  <h3 className="font-bold ">Add OCD Subtype tags</h3>

                  <p className="opacity-60 text-14px tablet:text-center max-w-100 mx-auto">
                    Tag this post with up to 4 specific OCD subtypes to increase
                    your chances of getting more relevant replies.
                  </p>

                  {topics?.subtypes?.map((subtype) => {
                    const isSelected = selectedTopicIds?.includes(subtype.id);
                    const isDisabled =
                      selectedSubtypes?.length >= 4 && !isSelected;

                    return (
                      <button
                        type="button"
                        key={subtype.id}
                        onClick={() => handleClickTopic(subtype.id)}
                        disabled={isDisabled}
                        className={cn(
                          "text-14px text-left rounded-lg py-3 px-8 flex items-center transition-all duration-200",
                          "gap-1 disabled:opacity-60 disabled:cursor-not-allowed",
                          isSelected
                            ? "text-indigo-600 border border-indigo-600 bg-indigo-100"
                            : "bg-gray-100"
                        )}
                      >
                        {isSelected ? (
                          <span>
                            <CheckIcon className="w-5 h-5" />
                          </span>
                        ) : null}
                        <span>{subtype.title}</span>
                      </button>
                    );
                  })}
                </div>
              </Modal>
            ) : null}

            {session && isCommunityGroupModalOpen ? (
              <Modal
                onClose={() => setIsCommunityGroupModalOpen(false)}
                isOpen={isCommunityGroupModalOpen}
              >
                <Modal.CloseButton
                  onClick={() => setIsCommunityGroupModalOpen(false)}
                />
                <div className="flex flex-col gap-3 px-6">
                  <h3 className="font-bold tablet:text-center">
                    Add NOCD Community group tags
                  </h3>

                  <p className="opacity-60 text-14px tablet:text-center max-w-100 mx-auto">
                    Tag this post with up to 4 specific community groups to
                    increase your chances of getting more relevant replies.
                  </p>

                  {topics?.communityGroups?.map((subtype) => {
                    const isSelected = selectedTopicIds?.includes(subtype.id);
                    const isDisabled =
                      selectedCommunityGroups?.length >= 4 && !isSelected;

                    return (
                      <button
                        type="button"
                        key={subtype.id}
                        disabled={isDisabled}
                        onClick={() => handleClickTopic(subtype.id)}
                        className={cn(
                          "text-14px text-left rounded-lg py-3 px-8 flex items-center transition-all duration-200",
                          "gap-1 disabled:opacity-60 disabled:cursor-not-allowed",
                          isSelected
                            ? "text-indigo-600 border border-indigo-600 bg-indigo-100"
                            : "bg-gray-100"
                        )}
                      >
                        {isSelected ? (
                          <span>
                            <CheckIcon className="w-5 h-5" />
                          </span>
                        ) : null}
                        <span>{subtype.title}</span>
                      </button>
                    );
                  })}
                </div>
              </Modal>
            ) : null}

            {session && isReassuranceCheckOpen ? (
              <ReassuranceModal
                isOpen={isReassuranceCheckOpen}
                closeWithoutAccepting={() =>
                  closeReassuranceModalWithoutAccepting()
                }
                acceptReassuranceCheck={() => closeReassuranceCheck()}
                variant={
                  reassuranceCheckData?.prompt_for_initial_reassurance_pledge
                    ? "initial"
                    : "general"
                }
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
