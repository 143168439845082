import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import Loader from "@core/ui/Loader";
import Modal, { useModal } from "@core/ui/Modal";
import { useSensitiveTopicStore } from "@features/community-v2/components/SensitiveContentDialog";
import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";

interface IntakeCallSchedulerModalProps {
  isOpen: boolean;
  handleClose: () => void;
  guid?: string;
  email?: string;
  source: string;
  insurance?: string;
  country?: string;
  state?: string;
}

export default function IntakeCallSchedulerModal({
  isOpen,
  handleClose,
  guid,
  email,
  source,
  insurance,
  country,
  state,
}: IntakeCallSchedulerModalProps): JSX.Element {
  const { focusRef } = useModal();
  const { data: session } = useSession();
  const {
    hasBeenClosed: hasSensitiveTopicDialogBeenClosed,
    setHasBeenClosed: setHasSensitiveTopicDialogBeenClosed,
    setIsOpen: setIsSensitiveTopicDialogOpen,
  } = useSensitiveTopicStore();
  const defaultUrl = useMemo(
    () =>
      `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/calendar?src=${source}${
        guid ? `&guid=${encodeURIComponent(guid)}` : ``
      }${email ? `&email=${encodeURIComponent(email)}` : ``}${
        insurance ? `&insurance=${encodeURIComponent(insurance)}` : ``
      }${country ? `&country=${encodeURIComponent(country)}` : ``}${
        state ? `&state=${encodeURIComponent(state)}` : ``
      }`,
    [source, guid, email, insurance, country, state]
  );

  useEffect(() => {
    if (isOpen && !hasSensitiveTopicDialogBeenClosed) {
      setHasSensitiveTopicDialogBeenClosed();
      setIsSensitiveTopicDialogOpen(false);
    }
  }, [
    isOpen,
    hasSensitiveTopicDialogBeenClosed,
    setHasSensitiveTopicDialogBeenClosed,
    setIsSensitiveTopicDialogOpen,
  ]);

  const { data: urlFromBackend, isLoading } = useQuery(
    ["get_scheduler_url", session?.accessToken],
    () =>
      api
        .get<string>("/v1/members/get_scheduler_url", {
          params: { src: source },
          headers: { Authorization: session?.accessToken },
        })
        .then(({ data }) => data)
        .catch((err: Error) => {
          console.error(err);

          // If it fails, just return the default one
          return defaultUrl;
        }),
    {
      enabled: !!session?.accessToken,
      // Don't refetch
      staleTime: Infinity,
    }
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      initialFocus={focusRef}
      backgroundColor="gray"
      size="medium"
      className="!m-0 w-full overflow-hidden rounded-lg !p-0 tablet:!max-w-[682px]"
    >
      {!isLoading ? (
        <iframe
          className="h-full min-h-[43rem] w-full"
          title="IntakeCallSchedulerModal"
          id="schedulerFrame"
          src={urlFromBackend || defaultUrl}
          frameBorder="0"
        />
      ) : (
        <Loader />
      )}

      <Modal.CloseButton onClick={handleClose} />
    </Modal>
  );
}
