import { api } from "@core/services/nocd-api";
import { InsuranceCardUploadStatus } from "@core/types";
import { ConversationType } from "@features/chatV2/types";
import { useQuery, UseQueryResult } from "react-query";

import { useSession } from "./useSession";

export const getQueryKey = (accessToken: string): [string, string] => [
  "me",
  accessToken,
];

export interface SchedulingPreferences {
  early_morning?: boolean;
  morning?: boolean;
  midday?: boolean;
  afternoon?: boolean;
  evening?: boolean;
  late_evening?: boolean;
  weekends?: boolean;
}

export type LinkedAccount = MemberLinkedAccount | RootLinkedAccount;

interface LinkedAccountBase {
  avatar_type: string;
  avatar_url: string;
  clinician_avatar_url: string;
  clinician_name: string;
  clinician_email: string;
  protocol_type: string;
  is_root_account: number;
  upcoming_appointments: number | null;
  community_handle: string;
  member_preferred_first_name: string | null;
  first_name: string | null;
  last_name: string | null;
  user_id: number;
  is_private_linked_account: boolean;
  user_email?: string;
  outstanding_tasks_count: number;
  unread_messages_count: number;
  phone?: string;
  address?: string;
}

type MemberLinkedAccount = LinkedAccountBase & {
  prompt_for_email_and_password_to_switch: false;
};

type RootLinkedAccount = LinkedAccountBase & {
  prompt_for_email_and_password_to_switch: true;
};

interface UserDTO {
  id: number;
  guid: string;
  email: string;
  community_handle: string;
  avatar_url: string;
  has_completed_community_onboarding: boolean;
  has_completed_therapy_session: boolean;
  has_upcoming_intake_call: boolean;
  is_premium_user: boolean;
  has_unread_chat_message: boolean;
  should_see_welcome_banner: boolean;
  insurance_card_upload_status: InsuranceCardUploadStatus | null;
  can_schedule_session: boolean;
  can_reschedule_session: boolean;
  can_cancel_session: boolean;
  can_transfer_therapist: boolean;
  integrated_therapy_experience_enabled: boolean;
  chat_channels: { channel_type: ConversationType; badge: boolean }[];
  state: string;
  display_profiles: boolean;
  profiles: LinkedAccount[];
  is_nocd_alumni: boolean;
  is_pre_therapy: boolean;
  link_id?: number;
  upcoming_appointments: number;
  member_preferred_first_name?: string;
  scheduling_preferences: SchedulingPreferences | null;
  user_badges?: string | "OCD_CONQUEROR"[];
  use_new_insurance_upload: boolean;
  child_adolescent_view_enabled?: boolean;
  is_nocd_clinician: boolean;
  clinician_state_licenses: string[];
}

const transformChannelsDTO = (
  channels: { channel_type: ConversationType; badge: boolean }[]
): { [key: string]: { unread: boolean } } => {
  const result: { [key: string]: { unread: boolean } } = {};
  channels?.forEach((channel) => {
    result[channel.channel_type] = { unread: !!channel.badge };
  });
  return result;
};

export interface User {
  id: number;
  guid: string;
  email: string;
  communityHandle: string;
  avatarUrl: string;
  hasCompletedCommunityOnboarding: boolean;
  hasCompletedTherapySession: boolean;
  hasUpcomingIntakeCall: boolean;
  isTherapyMember: boolean;
  hasUnreadChatMessage: boolean;
  shouldSeeTherapyWelcomeBanner: boolean;
  insuranceCardUploadStatus: InsuranceCardUploadStatus | null;
  canScheduleSession: boolean;
  canRescheduleSession: boolean;
  canCancelSession: boolean;
  canTransferTherapist: boolean;
  integratedTherapyExperienceEnabled: boolean;
  chatChannels: { [key: string]: { unread: boolean } };
  state: string;
  showLinkedAccounts: boolean;
  linkedAccounts: LinkedAccount[];
  isNocdAlumni: boolean;
  isPreTherapy: boolean;
  linkId: number;
  upcomingAppointments: number;
  preferredFirstName?: string;
  isRootAccount: boolean;
  schedulingPreferences: SchedulingPreferences | null;
  useNewInsuranceUpload: boolean;
  userBadges?: string | "OCD_CONQUEROR"[];
  childAdolescentViewEnabled: boolean;
  isNocdClinician: boolean;
  clinicianStateLicenses: string[] | null;
}

// temporarily adding request_channels query param for MA chat rollout
export const getUser = (
  accessToken: string,
  signal?: AbortSignal
): Promise<User> =>
  api
    .get<UserDTO>("/v2/me?chat_params=1", {
      headers: { Authorization: accessToken },
      signal,
    })
    .then(({ data }) => {
      return {
        id: data.id,
        guid: data.guid,
        email: data.email,
        communityHandle: data.community_handle,
        avatarUrl: data.avatar_url,
        state: data.state,
        hasCompletedCommunityOnboarding:
          data.has_completed_community_onboarding,
        hasCompletedTherapySession: data.has_completed_therapy_session,
        hasUpcomingIntakeCall: data.has_upcoming_intake_call,
        isTherapyMember: data.is_premium_user,
        hasUnreadChatMessage: data.has_unread_chat_message,
        shouldSeeTherapyWelcomeBanner: data.should_see_welcome_banner,
        insuranceCardUploadStatus: data.insurance_card_upload_status,
        canScheduleSession: data.can_schedule_session,
        canRescheduleSession: data.can_reschedule_session,
        canCancelSession: data.can_cancel_session,
        canTransferTherapist: data.can_transfer_therapist,
        integratedTherapyExperienceEnabled:
          data.integrated_therapy_experience_enabled,
        chatChannels: transformChannelsDTO(data.chat_channels),
        showLinkedAccounts: data.display_profiles,
        linkedAccounts: data.profiles ?? [],
        isNocdAlumni: data.is_nocd_alumni,
        isPreTherapy: data?.is_pre_therapy,
        linkId: data.link_id,
        upcomingAppointments: data.upcoming_appointments,
        preferredFirstName: data.member_preferred_first_name,
        isRootAccount:
          data.profiles?.find((acc) => acc.user_id === data.id)
            ?.is_root_account === 1,
        schedulingPreferences: data.scheduling_preferences,
        userBadges: data.user_badges,
        useNewInsuranceUpload: data.use_new_insurance_upload,
        childAdolescentViewEnabled: data.child_adolescent_view_enabled,
        isNocdClinician: Boolean(data.is_nocd_clinician),
        clinicianStateLicenses: data.clinician_state_licenses,
      };
    });

const useUser = (): UseQueryResult<User, Error> => {
  const { data: session } = useSession();

  return useQuery(
    getQueryKey(session?.accessToken),
    ({ signal }) => getUser(session?.accessToken, signal),
    { enabled: !!session?.accessToken }
  );
};

export default useUser;
