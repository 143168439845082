import Button from "@core/ui/Button";
import cn from "classnames";
import Image from "next/image";
import { ReactNode, useEffect } from "react";

import { NavigationLink } from "./types";

interface MobileMenuProps {
  callToAction: ReactNode;
  ctaButtonLabel: string;
  isOpen: boolean;
  navigationLinks: NavigationLink[];
  onClickCTA: () => void;
  onToggleMenu: () => void;
}

export default function MobileMenu({
  callToAction,
  ctaButtonLabel,
  isOpen,
  navigationLinks,
  onClickCTA,
  onToggleMenu,
}: MobileMenuProps): JSX.Element {
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.documentElement.style.overflow = isOpen ? "hidden" : "scroll";
    }
  }, [isOpen]);

  return (
    <>
      <header className="mx-auto flex h-[58px] max-w-[1072px] items-center justify-between space-x-12 py-4 px-6 laptop:hidden">
        <button
          type="button"
          className="h-[26px] w-[88px]"
          onClick={() => {
            window.location.href = process.env.NEXT_PUBLIC_WEBSITE_BASE_URL;
            return undefined;
          }}
        >
          <Image
            src="/images/nocd-logo.svg"
            alt="Go to homepage"
            width={120}
            height={35}
            priority
          />
        </button>

        <nav className="flex items-center space-x-4">
          {callToAction}
          <button
            type="button"
            onClick={onToggleMenu}
            aria-label={isOpen ? "Open menu" : "Close menu"}
          >
            <div
              className={cn(
                `mb-2 h-[2px] w-8 rounded bg-teal-600 transition-transform duration-300`,
                isOpen && "translate-y-3 rotate-45 transform"
              )}
            />
            <div
              className={cn(
                `mb-2 h-[2px] w-8 rounded bg-teal-600 transition-transform duration-300`,
                isOpen ? "opacity-0" : "opacity-100"
              )}
            />
            <div
              className={cn(
                `h-[2px] w-8 rounded bg-teal-600 transition-transform duration-300`,
                isOpen && "-translate-y-2 -rotate-45 transform"
              )}
            />
          </button>
        </nav>
      </header>

      <nav
        className={cn(
          "fixed overflow-y-scroll left-0 right-0 flex top-[58px] bottom-0 w-full transform bg-[#EDF2F7] transition-transform laptop:hidden",
          isOpen ? "translate-x-0" : "translate-x-full"
        )}
      >
        <div className="mx-auto flex max-w-[1072px] flex-1 flex-col px-6 pt-3">
          <ul className="flex-1 divide-y-2 divide-[#E2E8F0] text-16px font-bold">
            {navigationLinks
              .filter(({ hide }) => !hide)
              .map(({ Component, title, id }) => (
                <li key={id}>
                  <Component className="flex items-center justify-between py-3 font-bold">
                    {title}
                  </Component>
                </li>
              ))}
          </ul>

          <div className="flex flex-col justify-center space-y-4 pb-6">
            <Button
              disablePadding
              rounded="full"
              className="mx-auto !h-[36px] !min-h-[36px] !w-[162.64px] text-16px"
              onClick={onClickCTA}
            >
              {ctaButtonLabel}
            </Button>

            <span className="text-center text-14px text-gray-700">
              The fastest way to connect with us is by booking a free 15-minute
              call. For other questions,{" "}
              <a
                href="mailto:care@nocdhelp.com"
                className="text-center text-teal-600 underline"
              >
                contact us
              </a>
              .
            </span>
          </div>
        </div>
      </nav>
    </>
  );
}
