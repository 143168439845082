import cn from "classnames";
import { ReactElement, ReactNode, ReactText, useMemo } from "react";
import type { PolymorphicPropsWithoutRef } from "react-polymorphic-types";

import { CardContextProvider, useCardContext } from "./context";
import { Spacing, Variant } from "./types";

const CardHeaderDefaultElement = "h2";

interface CardHeaderOwnProps {
  icon?: ReactNode;
  title: ReactText | ReactElement;
  color?: "default" | "purple";
}

type CardHeaderProps<
  T extends React.ElementType = typeof CardHeaderDefaultElement
> = PolymorphicPropsWithoutRef<CardHeaderOwnProps, T>;

const CardHeader = <
  T extends React.ElementType = typeof CardHeaderDefaultElement
>({
  icon,
  title,
  as,
  color = "default",
  ...restProps
}: CardHeaderProps<T>) => {
  const Component: React.ElementType = as || CardHeaderDefaultElement;
  const { spacing } = useCardContext();

  return (
    <Component
      className={cn("flex items-center space-x-2 tablet:space-x-3", {
        "mb-6 text-14px font-bold tablet:text-20px": spacing === "default",
        "mb-3 text-sm font-semibold uppercase": spacing === "dense",
        "text-gray-800": color === "default",
        "text-purple-900": color === "purple",
      })}
      {...restProps}
    >
      {icon || null}
      <div className="flex-grow">{title}</div>
    </Component>
  );
};

const CardDefaultElement = "section";

interface CardOwnProps {
  children: ReactNode;
  className?: string;
  spacing?: Spacing;
  variant?: Variant;
  rounded?: "lg" | "xl" | "2xl";
}

type CardProps<T extends React.ElementType = typeof CardDefaultElement> =
  PolymorphicPropsWithoutRef<CardOwnProps, T>;

function Card<T extends React.ElementType = typeof CardDefaultElement>({
  children,
  className,
  as,
  spacing = "default",
  variant = "default",
  rounded = "lg",
  ...restProps
}: CardProps<T>): JSX.Element {
  const Component: React.ElementType = as || CardDefaultElement;

  const contextValue = useMemo(() => {
    return { spacing, variant };
  }, [spacing, variant]);

  return (
    <CardContextProvider value={contextValue}>
      <Component
        className={cn(
          "bg-white",
          {
            "px-4 py-6 tablet:px-6 tablet:py-8": spacing === "default",
            "p-4": spacing === "dense",
            shadow: variant === "default",
            "border border-gray-200": variant === "outlined",
            "rounded-lg": rounded === "lg",
            "rounded-xl": rounded === "xl",
            "rounded-2xl": rounded === "2xl",
          },
          className
        )}
        {...restProps}
      >
        {children}
      </Component>
    </CardContextProvider>
  );
}

Card.Header = CardHeader;

export default Card;
