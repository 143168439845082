import { useSession } from "@core/hooks/useSession";
import useUser from "@core/hooks/useUser";
import { api } from "@core/services/nocd-api";
import { AxiosError } from "axios";
import { isEmpty } from "lodash/fp";
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";

import { useSortOptionsStore } from "../components/ActionBar";
import transformPaginatedPosts from "../transformers/transformPaginatedPosts";
import {
  ApiPaginatedPosts,
  FeedContentFilters,
  FeedTypes,
  PaginatedPosts,
} from "../types";
import { useFeedOptions } from "./useFeedOptions";
import { UserFeedSettings, useUserFeedSettings } from "./useUserFeedSettings";

const buildSettingsKey = (userFeedSettings: UserFeedSettings): string => {
  const filters = (userFeedSettings?.filters as string[]) ?? [];
  return !isEmpty(filters) ? `&filters=${filters.join(",")}` : ``;
};

const getFeedTypeParam = (feedType: FeedTypes | string, subtype: string) => {
  if (subtype) {
    return "topics";
  }

  switch (feedType) {
    case FeedTypes.ALL_TOPICS:
    case "all":
      return FeedTypes.ALL_TOPICS;
    case FeedTypes.THERAPIST_GIVE_BACK:
      return FeedTypes.THERAPIST_GIVE_BACK;
    default:
      return FeedTypes.MY_TOPICS;
  }
};

export const getInfinitePostsQueryKey = (
  accessToken: string,
  userFeedSettings?: UserFeedSettings,
  subtype?: string,
  sortBy?: "Most recent" | "Most popular",
  feedType?: FeedTypes
): string[] =>
  [
    "all-posts",
    accessToken,
    buildSettingsKey(userFeedSettings),
    subtype,
    sortBy,
    feedType,
  ].filter(Boolean);

export const getPosts = ({
  segmentId,
  deviceId,
  accessToken,
  userFeedSettings,
  subtype,
  sortBy,
  feedType,
}: {
  segmentId?: string;
  deviceId?: string;
  accessToken?: string;
  userFeedSettings?: UserFeedSettings;
  subtype?: string;
  sortBy?: "new" | "popular";
  feedType?: FeedTypes;
}): Promise<PaginatedPosts> => {
  const queryObject = {
    segment_id: segmentId,
    filters: userFeedSettings?.filters,
    limit: 100,
    topic_ids: subtype,
    sort_by: sortBy,
    new_feed: segmentId ? undefined : 1,
    feed_type: getFeedTypeParam(feedType, subtype),
  };

  return api
    .get<ApiPaginatedPosts>(`/v3/posts`, {
      headers: {
        "X-DeviceID": deviceId,
        Authorization: accessToken,
      },
      params: queryObject,
    })
    .then(({ data: page }) => transformPaginatedPosts(page));
};

export const useInfinitePosts = (
  subtypeId?: string
): UseInfiniteQueryResult<PaginatedPosts, AxiosError> => {
  const { data: session } = useSession();
  const { accessToken, deviceId } = session ?? {};
  const { data: user } = useUser();
  const isChildAcct = !user?.isRootAccount && user?.linkedAccounts?.length;
  const { sortBy } = useSortOptionsStore();
  const { data: feedOptions } = useFeedOptions();

  const { feedSettingsByUser } = useUserFeedSettings();
  const userFeedSettings = isChildAcct
    ? { filters: [FeedContentFilters.NOCD] }
    : feedSettingsByUser?.[session?.user?.id];

  return useInfiniteQuery(
    getInfinitePostsQueryKey(
      accessToken,
      userFeedSettings,
      subtypeId,
      sortBy,
      feedOptions?.feedType
    ),
    ({ pageParam: segmentId }) =>
      getPosts({
        segmentId: segmentId as string,
        deviceId,
        accessToken,
        userFeedSettings,
        subtype: subtypeId,
        sortBy: sortBy === "Most recent" ? "new" : "popular",
        feedType: feedOptions?.feedType,
      }),
    {
      getNextPageParam: ({ cursors }) => cursors.next,
      enabled: !!accessToken,
    }
  );
};
