import { useSession } from "@core/hooks/useSession";
import useUser from "@core/hooks/useUser";
import ErrorScreen from "@core/layout/ErrorScreen";
import IntakeCallSchedulerModal from "@core/scheduler-modals/IntakeCallSchedulerModal";
import { SSRError } from "@core/types";
import { useEffect, useState } from "react";

import { newPostDialogStore } from "../components/ActionBar";
import Feed from "../components/Feed";
import LoadingFeed from "../components/Feed/LoadingFeed";
import PageWrapper from "../components/PageWrapper";
import SensitiveTopicDialog from "../components/SensitiveContentDialog";
import useIsConquerorPost from "../hooks/useIsConquerorPost";

interface MainFeedScreenProps {
  segmentId?: string;
  ssrError: SSRError;
}

export function MainFeedScreen({
  segmentId,
  ssrError,
}: MainFeedScreenProps): JSX.Element {
  const [error, setError] = useState<Error | null>(null);

  // User/session
  const { data: session, isLoading: isLoadingSession } = useSession();
  const { data: user, isLoading: isLoadingUser } = useUser();
  const { accessToken } = session ?? {};
  const isAuthenticated = !!accessToken;

  // Dialog/modal state
  const [hasNewPostModalOpened, setHasNewPostModalOpened] = useState(false);
  const { isConquerorPostEnabled } = useIsConquerorPost();
  const { onOpen: openNewPostDialog } = newPostDialogStore();

  useEffect(() => {
    if (isConquerorPostEnabled && !hasNewPostModalOpened) {
      openNewPostDialog();
      setHasNewPostModalOpened(true);
    }
  }, [hasNewPostModalOpened, isConquerorPostEnabled, openNewPostDialog]);

  const [schedulerState, setSchedulerState] = useState({
    isOpen: false,
    source: "web_app_community_feed",
  });

  const enableParentalControl = !!user?.childAdolescentViewEnabled;

  return (
    <PageWrapper
      classes={{ container: "!pb-0" }}
      hideSidebar={enableParentalControl}
    >
      {
        // eslint-disable-next-line no-nested-ternary
        isLoadingSession || isLoadingUser ? (
          <LoadingFeed />
        ) : ssrError || error ? (
          <ErrorScreen error={ssrError ?? error} />
        ) : (
          <div className="relative">
            <div className="space-y-3">
              <Feed
                setError={setError}
                segmentId={segmentId}
                isAuthenticated={isAuthenticated}
              />

              <IntakeCallSchedulerModal
                source={schedulerState.source}
                guid={user?.guid}
                email={user?.email}
                isOpen={schedulerState.isOpen}
                handleClose={() =>
                  setSchedulerState((previousState) => ({
                    ...previousState,
                    isOpen: false,
                  }))
                }
              />
            </div>
          </div>
        )
      }

      {!session ? <SensitiveTopicDialog /> : null}
    </PageWrapper>
  );
}
