import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { DateTime, InsuranceCardUploadStatus } from "@core/types";
import { transformDateTime } from "@core/utils/transformers";
import { AxiosError } from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import {
  Appointment,
  Charge,
  Coverage,
  MemberStatus,
  Overview,
  PaymentPlan,
  Settings,
} from "../types";
import {
  AppointmentDto,
  ChargeDto,
  CoverageDto,
  MemberStatusDto,
  OverviewDto,
  PaymentPlanDto,
  SettingsDto,
  transformAppointmentDto,
  transformChargeDto,
  transformCoverageDto,
  transformMemberStatusDto,
  transformOverviewDto,
  transformPaymentPlanDto,
  transformSettingsDto,
} from "../utils/transformers";

export interface PaymentMethod {
  card: {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
  };
}

interface Response {
  coverage: CoverageDto[];
  appointments: AppointmentDto[];
  charges: ChargeDto[];
  overview: OverviewDto;
  settings: SettingsDto;
  payment_plan?: PaymentPlanDto[];
  last_insurance_card_uploaded_by_user_at: string;
  member_insurance_expiring: boolean | null;
  need_eoy_insurance_upload: boolean | null;
  has_active_insurance_coverage: boolean;
  member_status: MemberStatusDto;
  show_payment_plan_banner: boolean;
  default_payment_method: PaymentMethod | null;
  linked_billing_infos: Response[] | null;
  insurance_card_upload_status: InsuranceCardUploadStatus | null;
  is_therapy_member: boolean;
  user_id: number;
  show_insurance_verification_in_network_banner: boolean;
  show_insurance_verification_out_of_network_banner: boolean;
  show_medicare_medicaid_out_of_network_banner: boolean;
  show_promotion_banner: boolean;
  completed_donated_appts: number | null;
}

export interface BillingInfo {
  coverage: Coverage[];
  appointments: Appointment[];
  charges: Charge[];
  overview: Overview;
  settings: Settings;
  paymentPlans: PaymentPlan[];
  lastInsuranceCardUploadedAt: DateTime;
  memberInsuranceExpiring: boolean | null;
  needEoyInsuranceUpload: boolean | null;
  hasActiveInsuranceCoverage: boolean;
  memberStatus: MemberStatus;
  showPaymentPlanBanner: boolean;
  defaultPaymentMethod: PaymentMethod | null;
  linkedBillingInfo: BillingInfo[] | null;
  insuranceCardUploadStatus: InsuranceCardUploadStatus | null;
  isTherapyMember: boolean;
  userId: number;
  showOutOfNetworkInsuranceBanner: boolean;
  showInNetworkInsuranceBanner: boolean;
  showOutOfNetworkMedicareMedicaidBanner: boolean;
  showPromotionBanner: boolean;
  completedDonatedAppts: number | null;
}

export const transformResponse = (data: Response): BillingInfo => {
  return {
    coverage: Array.isArray(data.coverage)
      ? data.coverage.map(transformCoverageDto)
      : [],
    appointments: data.appointments.map(transformAppointmentDto),
    charges: data.charges.map(transformChargeDto),
    overview: transformOverviewDto(data.overview),
    settings: transformSettingsDto(data.settings),
    paymentPlans: Array.isArray(data.payment_plan)
      ? data.payment_plan.map(transformPaymentPlanDto)
      : [],
    lastInsuranceCardUploadedAt: data.last_insurance_card_uploaded_by_user_at
      ? transformDateTime(data.last_insurance_card_uploaded_by_user_at, "P")
      : null,
    memberInsuranceExpiring: data.member_insurance_expiring,
    needEoyInsuranceUpload: data.need_eoy_insurance_upload,
    hasActiveInsuranceCoverage: data.has_active_insurance_coverage,
    memberStatus: data.member_status
      ? transformMemberStatusDto(data.member_status)
      : null,
    showPaymentPlanBanner: data.show_payment_plan_banner,
    defaultPaymentMethod: data.default_payment_method,
    linkedBillingInfo: Array.isArray(data.linked_billing_infos)
      ? data.linked_billing_infos.map(transformResponse)
      : null,
    insuranceCardUploadStatus: data.insurance_card_upload_status,
    isTherapyMember: data.is_therapy_member,
    userId: data.user_id,
    showOutOfNetworkInsuranceBanner:
      data.show_insurance_verification_out_of_network_banner,
    showInNetworkInsuranceBanner:
      data.show_insurance_verification_in_network_banner,
    showOutOfNetworkMedicareMedicaidBanner:
      data.show_medicare_medicaid_out_of_network_banner,
    showPromotionBanner: data.show_promotion_banner,
    completedDonatedAppts: data.completed_donated_appts,
  };
};

export const getQueryKey = (accessToken: string): [string, string] => [
  "billing-info",
  accessToken,
];

export const useBillingInfo = (
  options: UseQueryOptions<undefined, AxiosError, BillingInfo> = {}
): UseQueryResult<BillingInfo, AxiosError> => {
  const { data: session } = useSession();

  return useQuery(
    getQueryKey(session?.accessToken),
    ({ signal }): Promise<BillingInfo> =>
      api
        .get<Response>("/v3/members/billing-info", {
          headers: { Authorization: session?.accessToken },
          signal,
        })
        .then(({ data }) => transformResponse(data)),
    {
      ...options,
      enabled: !!session?.accessToken,
    }
  );
};
