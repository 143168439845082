import useUser from "@core/hooks/useUser";
import { RadioGroup } from "@headlessui/react";
import cn from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Control, Controller } from "react-hook-form";

import { FeedTypes } from "../../types";
import { FeedSettingsFormValues } from "./types";

const getFeedTypeOptions = (isTherapist: boolean) =>
  [
    {
      id: FeedTypes.MY_TOPICS,
      name: "My Feed",
      description: "Customize to my OCD subtypes",
    },
    {
      id: FeedTypes.ALL_TOPICS,
      name: "All Feed",
      description: "Includes everything everyone posted",
    },
    isTherapist && {
      id: FeedTypes.THERAPIST_GIVE_BACK,
      name: "Free users",
      description: "Free users that are in your licensed states",
    },
  ].filter(Boolean);

interface Props {
  isSubmitting?: boolean;
  control: Control<FeedSettingsFormValues>;
}

const FeedTypeOptions = ({ isSubmitting, control }: Props) => {
  const { data: user } = useUser();
  const isTherapist = user?.isNocdClinician;
  const { enableFreeUserFeed } = useFlags();

  return (
    <Controller
      name="feedType"
      control={control}
      render={({ field: { ref: _, ...field } }) => (
        <RadioGroup {...field} className="mb-8" disabled={isSubmitting}>
          <RadioGroup.Label className="block mb-2 font-bold text-gray-800 text-16px">
            Default feed
          </RadioGroup.Label>

          <div className="-space-y-px bg-white rounded-md">
            {getFeedTypeOptions(Boolean(isTherapist && enableFreeUserFeed)).map(
              (feedType, feedTypeIndex) => (
                <RadioGroup.Option
                  key={feedType.id}
                  onChange={field.onChange}
                  value={feedType.id}
                  className={cn(
                    feedTypeIndex === 0 && "rounded-tl-md rounded-tr-md",

                    feedTypeIndex ===
                      getFeedTypeOptions(
                        Boolean(isTherapist && enableFreeUserFeed)
                      ).length -
                        1 && "rounded-bl-md rounded-br-md",
                    "focus:outline-none relative flex cursor-pointer items-start py-2"
                  )}
                >
                  {({ active, checked }) => (
                    <>
                      <span
                        className={cn(
                          checked
                            ? "border-transparent bg-indigo-600"
                            : "border-gray-300 bg-white",
                          active && "ring-2 ring-indigo-500 ring-offset-2",
                          "flex h-4 w-4 cursor-pointer items-center justify-center rounded-full border"
                        )}
                        aria-hidden="true"
                      >
                        <span className="w-1.5 h-1.5 bg-white rounded-full" />
                      </span>

                      <div className="flex flex-col flex-1 ml-3">
                        <RadioGroup.Label
                          as="span"
                          className={cn(
                            "mb-1 leading-none block text-16px font-medium text-gray-900"
                          )}
                        >
                          {feedType.name}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={cn("block text-14px text-gray-500")}
                        >
                          {feedType.description}
                        </RadioGroup.Description>
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              )
            )}
          </div>
        </RadioGroup>
      )}
    />
  );
};

export default FeedTypeOptions;
