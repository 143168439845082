import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import Button from "@core/ui/Button";
import BaseCard from "@core/ui/Card";
import Loader from "@core/ui/Loader";
import Modal from "@core/ui/Modal";
import RefreshIcon from "@heroicons/react/outline/RefreshIcon";
import Image from "next/image";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useQuery, UseQueryResult } from "react-query";

import { PostResult } from "../hooks/usePost";

interface AiSuggestionsResponse {
  input: {
    message: string | null;
    chat_channel_id: number | null;
  };
  suggestions: {
    id: string;
    text: string;
    traceId: string;
  }[];
}

const useAiSuggestions = (payload: {
  post_id: number;
  posting_title: string;
  post_message: string;
  draft_message: string;
}): UseQueryResult<AiSuggestionsResponse, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    [
      "ai-suggestions",
      "community-post",
      payload.draft_message,
      payload.post_id,
      accessToken,
    ],
    ({ signal }) =>
      api
        .post<AiSuggestionsResponse>(`/v1/community/ai_suggestions`, payload, {
          headers: {
            Authorization: accessToken,
          },
          signal,
        })
        .then(({ data }) => data)
        .catch((error: Error) => toast.error(error.message)),

    {
      // This query should only be triggered manually.
      enabled: false,
      // The endpoint is not idempotent, so it should not be refetched
      // automatically.
      staleTime: Infinity,
    }
  );
};

interface AiPostReplySuggestionsDialogProps {
  draftMessage: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (message: string) => void;
  query: UseQueryResult<AiSuggestionsResponse, Error>;
}

const AiPostReplySuggestionsDialog = ({
  draftMessage,
  isOpen,
  onClose,
  onSave,
  query,
}: AiPostReplySuggestionsDialogProps) => {
  const [selectedSuggestionId, setSelectedSuggestionId] = useState<
    AiSuggestionsResponse["suggestions"][0]["id"] | null
  >(null);

  const handleModalClose = () => {
    setSelectedSuggestionId(null);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose} className="!p-8">
      <Modal.CloseButton onClick={handleModalClose} />
      <div className="flex flex-col gap-5">
        <div className="mt-2">
          <h2>Suggestions</h2>
        </div>
        {draftMessage ? (
          <div className="flex flex-col gap-2">
            <BaseCard
              variant="outlined"
              rounded="xl"
              className={`!p-2 ${
                selectedSuggestionId === null
                  ? "border-2 border-indigo-500"
                  : ""
              }`}
            >
              <button
                type="button"
                onClick={() => setSelectedSuggestionId(null)}
                className="w-full p-2 text-left rounded-xl"
              >
                {draftMessage}
              </button>
            </BaseCard>
            <p>Here are some suggestions for an empathetic response...</p>
          </div>
        ) : (
          <p>Here are some suggestions for an empathetic response...</p>
        )}
        {/* eslint-disable-next-line no-nested-ternary */}
        {query.isFetching ? (
          <div className="flex text-32px text-indigo-600 justify-center items-center py-12">
            <Loader />
          </div>
        ) : query.error ? (
          <div className="flex flex-col gap-4">
            <p>We weren&apos;t able to generate any suggestions.</p>
            <div className="flex justify-center">
              <Button
                onClick={() => query.refetch()}
                disabled={query.isFetching}
                variant="text"
              >
                Try again
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-5">
            {(query?.data?.suggestions ?? []).map((suggestion) => {
              const isSelected = selectedSuggestionId === suggestion.id;
              return (
                <BaseCard
                  key={suggestion.id}
                  variant="outlined"
                  rounded="xl"
                  className={`!p-2 ${
                    isSelected ? "!bg-[#add9e6]" : "!bg-[#f5f5f5]"
                  }`}
                >
                  <button
                    type="button"
                    onClick={() =>
                      setSelectedSuggestionId((previous) =>
                        previous !== suggestion.id ? suggestion.id : null
                      )
                    }
                    className={`w-full p-2 text-left rounded-xl ${
                      isSelected ? "bg-[#add9e6]" : "hover:bg-gray-200"
                    }`}
                  >
                    {suggestion?.text}
                  </button>
                </BaseCard>
              );
            })}
            <Button
              type="button"
              onClick={() => {
                setSelectedSuggestionId(null);
                void query.refetch();
              }}
              variant="text"
              disabled={query.isFetching}
            >
              <div className="flex items-center gap-2">
                <RefreshIcon className="w-4 h-4" />
                <p>Regenerate suggestions</p>
              </div>
            </Button>
          </div>
        )}

        <div className="flex justify-end gap-10">
          <Button color="light-gray" onClick={handleModalClose} variant="text">
            Cancel
          </Button>

          <Button
            variant="text"
            onClick={() => {
              const selectedSuggestion = (query.data?.suggestions ?? []).find(
                (s) => s.id === selectedSuggestionId
              );
              const initialDraftMessage = draftMessage;
              let acceptedSuggestion: string | null = null;

              if (selectedSuggestion) {
                acceptedSuggestion = selectedSuggestion.text;
              } else if (initialDraftMessage) {
                acceptedSuggestion = initialDraftMessage;
              }

              onSave(acceptedSuggestion);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const AiCommunitySuggestionsButton = ({
  draftMessage,
  post,
  onSave,
}: {
  draftMessage: string;
  post: PostResult;
  onSave: (message: string) => void;
}) => {
  const [isAiSuggestionsOpen, setIsAiSuggestionsOpen] = useState(false);
  const payload = {
    post_id: post?.post?.id,
    posting_title: post?.post?.posting_title,
    post_message: post?.post?.body,
    draft_message: draftMessage,
  };

  const query = useAiSuggestions(payload);
  const handleSave = (message: string) => {
    onSave(message);
    setIsAiSuggestionsOpen(false);
  };

  return (
    <>
      <button
        disabled={query.isFetching}
        type="button"
        className="group flex items-center space-x-1"
        onClick={() => {
          setIsAiSuggestionsOpen(true);
          void query.refetch();
        }}
      >
        <Image
          src="/images/community/magic-wand.svg"
          width="16"
          height="16"
          alt="Magic wand"
        />
      </button>

      {isAiSuggestionsOpen ? (
        <AiPostReplySuggestionsDialog
          draftMessage={draftMessage}
          onSave={handleSave}
          isOpen={isAiSuggestionsOpen}
          onClose={() => setIsAiSuggestionsOpen(false)}
          query={query}
        />
      ) : null}
    </>
  );
};

export default AiCommunitySuggestionsButton;
