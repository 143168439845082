import { useSession } from "@core/hooks/useSession";
import useUser from "@core/hooks/useUser";
import { api } from "@core/services/nocd-api";
import Modal from "@core/ui/Modal";
import Tooltip from "@core/ui/Tooltip";
import { useAuthenticationModal } from "@features/auth";
import { Popover } from "@headlessui/react";
import {
  AdjustmentsIcon,
  ChevronDownIcon,
  InformationCircleIcon,
  PlusIcon,
  SearchIcon,
  XIcon,
} from "@heroicons/react/solid";
import cn from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { FC, useCallback, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import Sticky from "react-stickynode";
import create from "zustand";

import { useFeedOptions } from "../hooks/useFeedOptions";
import { useMutateFeedOptions } from "../hooks/useMutateFeedOptions";
import { useUserFeedSettings } from "../hooks/useUserFeedSettings";
import { FeedTypes, PostExt, PostTypes } from "../types";
import ClinicianFirstVisitDialog from "./ClinicianFirstVisitDialog";
import { feedSettingsDialogStore } from "./FeedSettingsDialog";
import NewPost from "./NewPost";

export const newPostDialogStore = create<{
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}>((set) => ({
  isOpen: false,
  onClose: () => set({ isOpen: false }),
  onOpen: () => set({ isOpen: true }),
}));

interface SearchResult {
  id: string;
  type: "post";
  post: PostExt;
}

interface SearchCommunityResponse {
  results: { data: SearchResult }[];
}

const searchCommunity = (
  searchTerm: string,
  accessToken: string
): Promise<SearchResult[]> =>
  api
    .post<SearchCommunityResponse>(
      `/v2/community/search`,
      {
        content_type: "posts",
        query: searchTerm,
      },
      accessToken
        ? {
            headers: { Authorization: accessToken },
          }
        : undefined
    )
    .then(({ data }) =>
      data.results
        // Make sure we're only returning posts
        // .filter((result) => result.data.type === "post")
        .map((result) => result.data)
        // Ensure the post has a body
        .filter((post) => !!post.post.body)
        // We only support text for now
        .filter((result) =>
          [PostTypes.TEXT, PostTypes.TEXT_REPLY].includes(result.post.post_type)
        )
    );

export const useCommunitySearch = (searchTerm: string, accessToken: string) => {
  return useQuery(
    ["community-search", searchTerm, accessToken],
    () => searchCommunity(searchTerm, accessToken),
    { enabled: !!searchTerm, staleTime: Infinity }
  );
};

const SearchBar: FC<{ setSearchTerm: (term: string) => void }> = ({
  setSearchTerm,
}) => {
  const searchTermRef = useRef<HTMLInputElement>(null);

  const handleSearch = useCallback(() => {
    setSearchTerm(searchTermRef.current.value);
  }, [setSearchTerm]);

  return (
    <div className="flex py-1 items-center px-2 rounded-xl bg-gray-100 relative w-full">
      <button onClick={handleSearch} type="button" className="flex-shrink-0">
        <span className="sr-only">Search community</span>
        <Tooltip
          content={<div className="text-12px text-white">Search</div>}
          side="top"
          portalled
        >
          <SearchIcon className="h-6 w-6 text-gray-600 tablet:animate-none animate-pulse mt-1" />
        </Tooltip>
      </button>

      <input
        type="text"
        ref={searchTermRef}
        placeholder="Search community"
        className="flex-1 text-16px tablet:text-14px leading-none bg-transparent border-none outline-none focus:ring-0"
        onKeyDown={(e) => e.key === "Enter" && handleSearch()}
      />
    </div>
  );
};

const getFeedOptionButtonClasses = (isActive: boolean) => {
  return cn(
    isActive
      ? "text-indigo-600 border border-indigo-600 bg-indigo-100"
      : "bg-gray-100 border border-gray-200 opacity-60 text-gray-600",
    "p-2 rounded-lg text-12px font-semibold flex items-center gap-1"
  );
};

export const ClinicianFeedOptionButtons = ({
  className,
  clinicianStateLicenses,
  clinicianUserId,
}: {
  className?: string;
  clinicianStateLicenses: string[] | null;
  clinicianUserId: number;
}) => {
  const { data: feedOptions } = useFeedOptions();
  const { mutateAsync } = useMutateFeedOptions();
  const currentFeed = feedOptions?.feedType;
  const queryClient = useQueryClient();
  const { feedSettingsByUser } = useUserFeedSettings();
  const userFeedSettings = feedSettingsByUser[clinicianUserId];

  const statesOfLicensure = !isEmpty(clinicianStateLicenses)
    ? clinicianStateLicenses.join(", ")
    : "licensed states";

  const router = useRouter();

  const handleFeedOptionClick = useCallback(
    (feedType: FeedTypes) => {
      if (router?.asPath !== "/community/posts") {
        router?.push("/community/posts").catch((err) => console.error(err));
      }

      mutateAsync({
        feedType,
        filters: userFeedSettings?.filters ?? [],
        topicIds: [
          ...(feedOptions?.categories?.community_filters?.topics || [])
            .filter((topic) => topic.isSelected)
            .map((topic) => topic.id),
          ...(feedOptions?.categories?.subtypes?.topics || [])
            .filter((topic) => topic.isSelected)
            .map((topic) => topic.id),
        ],
      })
        .then(() => queryClient.invalidateQueries(["all-posts"]))
        .catch((err) => console.error(err));
    },
    [feedOptions, mutateAsync, userFeedSettings?.filters, queryClient, router]
  );

  return (
    <div className={cn("flex gap-3", className)}>
      <button
        className={getFeedOptionButtonClasses(
          [FeedTypes.ALL_TOPICS, "all"].includes(currentFeed)
        )}
        onClick={() => handleFeedOptionClick(FeedTypes.ALL_TOPICS)}
        type="button"
      >
        All
      </button>

      <button
        className={getFeedOptionButtonClasses(
          currentFeed === FeedTypes.THERAPIST_GIVE_BACK
        )}
        onClick={() => handleFeedOptionClick(FeedTypes.THERAPIST_GIVE_BACK)}
        type="button"
      >
        <span>Free users</span>

        <Tooltip
          content={`${
            currentFeed === FeedTypes.THERAPIST_GIVE_BACK
              ? "You're only viewing"
              : "View only"
          } posts from members in ${statesOfLicensure}`}
        >
          <InformationCircleIcon className="w-4 h-4" />
        </Tooltip>
      </button>

      <button
        className={getFeedOptionButtonClasses(
          [FeedTypes.MY_TOPICS, "my"].includes(currentFeed)
        )}
        onClick={() => handleFeedOptionClick(FeedTypes.MY_TOPICS)}
        type="button"
      >
        My Feed
      </button>
    </div>
  );
};

export const useSortOptionsStore = create<{
  sortBy: "Most recent" | "Most popular";
  setSortBy: (sortBy: "Most recent" | "Most popular") => void;
}>((set) => ({
  sortBy: "Most recent",
  setSortBy: (sortBy: "Most recent" | "Most popular") => set({ sortBy }),
}));

const ActionBar: FC<{
  setSearchTerm: (term: string) => void;
}> = ({ setSearchTerm }) => {
  const { onOpen: openFeedSettingsDialog } = feedSettingsDialogStore();
  const { data: user } = useUser();
  const enableParentalControl = !!user?.childAdolescentViewEnabled;
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { data: session } = useSession();
  const isAuthenticated = !!session?.accessToken;
  const { openModal } = useAuthenticationModal();
  const { enableFreeUserFeed } = useFlags();

  const {
    isOpen: isNewPostDialogOpen,
    onClose: closeNewPostDialog,
    onOpen: openNewPostDialog,
  } = newPostDialogStore();

  return (
    <>
      <Sticky innerZ={11} top={50}>
        {(status) => (
          <div
            className={cn(
              status.status === Sticky.STATUS_FIXED
                ? "shadow-navbar-scrolled tablet:pt-8 pt-4"
                : "rounded-t-xl",
              "relative px-6 bg-white py-3 font-poppins"
            )}
          >
            {isSearchOpen ? (
              <div className="w-full relative">
                <SearchBar setSearchTerm={setSearchTerm} />

                <button
                  type="button"
                  className="absolute top-3 right-3 z-1"
                  onClick={() => {
                    setIsSearchOpen(false);
                    setSearchTerm(null);
                  }}
                >
                  <span className="sr-only">Clear search</span>
                  <Tooltip
                    content={
                      <div className="text-12px text-white">Close search</div>
                    }
                    side="top"
                    portalled
                  >
                    <XIcon className="w-4 h-4" />
                  </Tooltip>
                </button>
              </div>
            ) : null}

            {!isSearchOpen ? (
              <>
                <div className="flex gap-3 items-center justify-between w-full">
                  <div className="flex gap-3 items-center flex-grow justify-between tablet:justify-start">
                    <Popover>
                      <Popover.Button>
                        <div className="flex items-center">
                          <span>{useSortOptionsStore.getState().sortBy}</span>
                          <span>
                            <ChevronDownIcon className="w-5 h-5" />
                          </span>
                        </div>
                      </Popover.Button>

                      <Popover.Panel className="absolute z-10 mt-1 bg-white shadow border border-gray-200 rounded-lg">
                        <p className="font-semibold p-2">Sort By</p>

                        <Popover.Button
                          type="button"
                          onClick={() => {
                            return isAuthenticated
                              ? useSortOptionsStore
                                  .getState()
                                  .setSortBy("Most popular")
                              : openModal();
                          }}
                          className={cn(
                            "block p-2 w-full text-left",
                            useSortOptionsStore.getState().sortBy ===
                              "Most popular" && "text-teal-600"
                          )}
                        >
                          Most popular
                        </Popover.Button>

                        <Popover.Button
                          className={cn(
                            "block p-2 w-full text-left",
                            useSortOptionsStore.getState().sortBy ===
                              "Most recent" && "text-teal-600"
                          )}
                          type="button"
                          onClick={() => {
                            return isAuthenticated
                              ? useSortOptionsStore
                                  .getState()
                                  .setSortBy("Most recent")
                              : openModal();
                          }}
                        >
                          Most recent
                        </Popover.Button>
                      </Popover.Panel>
                    </Popover>

                    <button
                      className={cn(
                        "hover:text-teal-600 h-full flex items-center justify-center ml-auto tablet:ml-0",
                        !isAuthenticated || enableParentalControl
                          ? "invisible"
                          : null
                      )}
                      type="button"
                      onClick={openFeedSettingsDialog}
                    >
                      <Tooltip
                        content={
                          <div className="text-12px text-white">
                            Customize feed
                          </div>
                        }
                        side="top"
                        portalled
                      >
                        <AdjustmentsIcon className="w-5 h-5" />
                      </Tooltip>
                    </button>
                  </div>

                  <div className="flex gap-3 items-center">
                    <button
                      type="button"
                      className={cn(
                        !isAuthenticated && "invisible",
                        "inline-flex items-center justify-center"
                      )}
                      onClick={openNewPostDialog}
                    >
                      <Tooltip
                        content={
                          <div className="text-12px text-white">New Post</div>
                        }
                        side="top"
                        portalled
                      >
                        <PlusIcon className="h-6 w-6 text-gray-600" />
                      </Tooltip>
                    </button>

                    <button
                      className="inline-flex items-center justify-center"
                      type="button"
                      onClick={() => setIsSearchOpen(true)}
                    >
                      <Tooltip
                        content={
                          <div className="text-12px text-white">Search</div>
                        }
                        side="top"
                        portalled
                      >
                        <SearchIcon className="h-6 w-6 text-gray-600" />
                      </Tooltip>
                    </button>
                  </div>
                </div>

                {enableFreeUserFeed &&
                isAuthenticated &&
                user?.isNocdClinician ? (
                  <ClinicianFeedOptionButtons
                    className="mt-4"
                    clinicianStateLicenses={user?.clinicianStateLicenses}
                    clinicianUserId={user?.id}
                  />
                ) : null}
              </>
            ) : null}
          </div>
        )}
      </Sticky>

      {isAuthenticated ? (
        <Modal
          onClose={() => closeNewPostDialog()}
          isOpen={isNewPostDialogOpen}
        >
          <NewPost
            onSuccess={() => closeNewPostDialog()}
            className="tablet:shadow-none"
          />

          <Modal.CloseButton onClick={() => closeNewPostDialog()} />
        </Modal>
      ) : null}

      {user?.isNocdClinician ? <ClinicianFirstVisitDialog /> : null}
    </>
  );
};

export default ActionBar;
