import { Post, PostExt } from "../types";

const transformPost = (post: Post): PostExt => {
  const reactionCount: number = post.reactions.reduce(
    (acc, curr) => acc + curr.count,
    0
  );

  return { ...post, likes: reactionCount }; // adding reaction count to likes to make number match between app & web
};

export default transformPost;
