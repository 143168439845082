import { ReactNode } from "react";

export enum LinkPositions {
  START,
  END,
  NONE,
}

export interface NavigationLink {
  id: string;
  title: string;
  desktopPosition: LinkPositions;
  Component: ({
    children,
    className,
  }: {
    children: ReactNode;
    className?: string;
  }) => JSX.Element;
  hide?: boolean;
}
