import { createContext, FC, useContext } from "react";

import { Spacing, Variant } from "./types";

const CardContext = createContext<CardContextValue>(null);

export interface CardContextValue {
  spacing?: Spacing;
  variant?: Variant;
}

export const CardContextProvider: FC<{ value: CardContextValue }> = ({
  value,
  children,
}) => {
  return <CardContext.Provider value={value}>{children}</CardContext.Provider>;
};

export const useCardContext = (): CardContextValue => {
  return useContext(CardContext);
};
